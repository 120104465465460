import metaBg from '@/assets/meta/meta_bg.png';

const identity = 'company';
const companyRouter = {
  path: 'company',
  component: () => import('@/views/memberCenter/page/HomeView.vue'),
  children: [
    // 首頁
    {
      path: 'home',
      name: 'companyHome',
      component: () => import('@/views/memberCenter/page/common/home/HomeView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
        ],
        title: 'memberCenter.home',
        description: 'about.description1',
        image: metaBg,
        scrollAnchor: true,
        requiredAuth: true,
      },
    },
    // 帳號
    {
      path: 'account',
      component: () => import('@/views/memberCenter/page/common/account/AccountView.vue'),
      children: [
        {
          path: '',
          name: 'companyAccount',
          component: () => import('@/views/memberCenter/page/common/account/InfoView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'companyAccount',
              },
            ],
            title: 'memberCenter.account',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'certification',
          name: 'companyCertification',
          component: () => import('@/views/memberCenter/page/common/account/certification/AccountCertification.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'companyAccount',
              },
              {
                name: 'breadcrumbList.certification',
                pathName: 'companyCertification',
              },
            ],
            title: 'breadcrumbList.certification',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'work-info',
          name: 'companyWorkInfo',
          component: () => import('@/views/memberCenter/page/common/account/workData/AccountWorkData.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'companyAccount',
              },
              {
                name: 'breadcrumbList.workData',
                pathName: 'companyWorkInfo',
              },
            ],
            title: 'breadcrumbList.workData',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'sub-account',
          name: 'companySubMangement',
          component: () => import('@/views/memberCenter/page/common/account/subAccount/SubAccountView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'companyAccount',
              },
              {
                name: 'breadcrumbList.subAccount',
                pathName: 'companySubMangement',
              },
            ],
            title: 'breadcrumbList.subAccount',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'account/change-phone',
      name: 'companyChangePhone',
      component: () => import('@/views/memberCenter/page/common/account/ChangePhone.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'companyAccount',
          },
          {
            name: 'breadcrumbList.changePhone',
            pathName: 'companyChangePhone',
          },
        ],
        title: 'breadcrumbList.changePhone',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'account/change-password',
      name: 'companyChangePwd',
      component: () => import('@/views/memberCenter/page/common/account/ChangePwd.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'companyAccount',
          },
          {
            name: 'breadcrumbList.changePwd',
            pathName: 'companyChangePwd',
          },
        ],
        title: 'breadcrumbList.changePwd',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 訊息
    {
      path: 'message',
      component: () => import('@/views/memberCenter/page/common/message/MessageLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyMessage',
          component: () => import('@/views/memberCenter/page/common/message/message/MessageView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'companyMessage',
              },
            ],
            title: 'breadcrumbList.message',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/house',
          name: 'companyHouseQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/HouseQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'companyMessage',
              },
              {
                name: 'breadcrumbList.houseQA',
                pathName: 'companyHouseQA',
              },
            ],
            title: 'breadcrumbList.houseQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/shop',
          name: 'companyStoreQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/StoreQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'companyMessage',
              },
              {
                name: 'breadcrumbList.storeQA',
                pathName: 'companyStoreQA',
              },
            ],
            title: 'breadcrumbList.storeQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'message/content/:id',
      name: 'companyMessageContent',
      component: () => import('@/views/memberCenter/page/common/message/message/MessageDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'companyMessage',
          },
          {
            name: 'breadcrumbList.messageContent',
            pathName: 'companyMessageContent',
          },
        ],
        title: 'breadcrumbList.messageContent',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/house/content/:id',
      name: 'companyHouseQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/HouseQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'companyMessage',
          },
          {
            name: 'breadcrumbList.houseQA',
            pathName: 'companyHouseQA',
          },
          {
            name: ':id',
            pathName: 'companyHouseQAContent',
          },
        ],
        title: 'breadcrumbList.houseQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/shop/content/:id',
      name: 'companyStoreQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/StoreQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'companyMessage',
          },
          {
            name: 'breadcrumbList.storeQA',
            pathName: 'companyStoreQA',
          },
          {
            name: ':id',
            pathName: 'companyStoreQAContent',
          },
        ],
        title: 'breadcrumbList.storeQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 刊登
    {
      path: 'publish/:category',
      component: () => import('@/views/memberCenter/page/common/publish/PublishLayout.vue'),
      children: [
        {
          path: '',
          name: `${identity}Publish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'single',
          name: `${identity}SinglePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.singlePosting',
                pathName: `${identity}SinglePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'rental',
          name: `${identity}RentalPublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.rentalPlanPublication',
                pathName: `${identity}RentalPublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'sale',
          name: `${identity}SalePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.salePlanPublication',
                pathName: `${identity}SalePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: `${identity}ClosedHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/close/ClosedHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesClosed',
                pathName: `${identity}ClosedHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesClosed',
            description: 'about.description1',
            image: metaBg,
            tabId: 2,
            requiredAuth: true,
          },
        },
        {
          path: 'deal',
          name: `${identity}DealHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/deal/DealHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.removedHouses',
                pathName: `${identity}DealHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.removedHouses',
            description: 'about.description1',
            image: metaBg,
            tabId: 3,
            requiredAuth: true,
          },
        },
        {
          path: 'edit',
          name: `${identity}EditHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/edit/EditHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesEdit',
                pathName: `${identity}EditHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesEdit',
            description: 'about.description1',
            image: metaBg,
            tabId: 4,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'publish/:category/rental/:id',
      name: `${identity}RentalPublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}RentalPublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/sale/:id',
      name: `${identity}SalePublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/rental/:id/edit',
      name: `${identity}HouseEditRentalPublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditRentalPublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/sale/:id/edit',
      name: `${identity}HouseEditSalePublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditSalePublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/rental/:id/edit',
      name: `${identity}FactoryEditRentalPublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditRentalPublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/sale/:id/edit',
      name: `${identity}FactoryEditSalePublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditSalePublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/rental/:id/edit',
      name: `${identity}LandEditRentalPublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditRentalPublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/sale/:id/edit',
      name: `${identity}LandEditSalePublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditSalePublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/rental/:id/edit',
      name: `${identity}OfficeEditRentalPublication`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeEditRentalPublication`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/sale/:id/edit',
      name: `${identity}OfficeEditSalePublication`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeEditSalePublication`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/rental/:id/edit',
      name: `${identity}ShopEditRentalPublication`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopEditRentalPublication`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/sale/:id/edit',
      name: `${identity}ShopEditSalePublication`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopEditSalePublication`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/rental/:id/improve',
      name: `${identity}ImprovetRentalPublication`,
      component: () => import('@/views/publish/ThirdStep.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improveEffect',
            pathName: `${identity}ImprovetRentalPublication`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improveEffect',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/bonus/:type/:id',
      name: `${identity}HouseBonus`,
      component: () => import('@/views/memberCenter/page/common/publish/exposure/ExposureView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improve',
            pathName: `${identity}HouseBonus`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improve',
        description: 'about.description1',
        image: metaBg,
        tabId: 1,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/rental/:id',
      name: `${identity}DealRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/sale/:id',
      name: `${identity}DealSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/rental/:id',
      name: `${identity}ClosedRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/sale/:id',
      name: `${identity}ClosedSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    // 編輯成交
    {
      path: 'publish/house/deal/rental/:id/edit',
      name: `${identity}HouseDealEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/deal/sale/:id/edit',
      name: `${identity}HouseDealEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/rental/:id/edit',
      name: `${identity}FactoryDealEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/sale/:id/edit',
      name: `${identity}FactoryDealEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/rental/:id/edit',
      name: `${identity}LandDealEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/sale/:id/edit',
      name: `${identity}LandDealEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/deal/rental/:id/edit',
      name: `${identity}OfficeDealEditRental`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeDealEditRental`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/deal/sale/:id/edit',
      name: `${identity}OfficeDealEditSale`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeDealEditSale`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/deal/rental/:id/edit',
      name: `${identity}ShopDealEditRental`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopDealEditRental`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/deal/sale/:id/edit',
      name: `${identity}ShopDealEditSale`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopDealEditSale`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯關閉
    {
      path: 'publish/house/closed/rental/:id/edit',
      name: `${identity}HouseClosedEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/closed/sale/:id/edit',
      name: `${identity}HouseClosedEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/rental/:id/edit',
      name: `${identity}FactoryClosedEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/sale/:id/edit',
      name: `${identity}FactoryClosedEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/rental/:id/edit',
      name: `${identity}LandClosedEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/sale/:id/edit',
      name: `${identity}LandClosedEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/closed/rental/:id/edit',
      name: `${identity}OfficeClosedEditRental`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeClosedEditRental`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/closed/sale/:id/edit',
      name: `${identity}OfficeClosedEditSale`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeClosedEditSale`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/closed/rental/:id/edit',
      name: `${identity}ShopClosedEditRental`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopClosedEditRental`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/closed/sale/:id/edit',
      name: `${identity}ShopClosedEditSale`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopClosedEditSale`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯未刊登
    {
      path: 'publish/house/edit-rental/:id',
      name: `${identity}EditRentalHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/edit-sale/:id',
      name: `${identity}EditSaleHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-rental/:id',
      name: `${identity}EditRentalFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-sale/:id',
      name: `${identity}EditSaleFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-rental/:id',
      name: `${identity}EditRentalLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-sale/:id',
      name: `${identity}EditSaleLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/edit-rental/:id',
      name: `${identity}EditRentalOffice`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: `${identity}Home`,
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalOffice`,
            category: 'office',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/edit-sale/:id',
      name: `${identity}EditSaleOffice`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: `${identity}Home`,
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleOffice`,
            category: 'office',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/edit-rental/:id',
      name: `${identity}EditRentalShop`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: `${identity}Home`,
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalShop`,
            category: 'shop',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/edit-sale/:id',
      name: `${identity}EditSaleShop`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: `${identity}Home`,
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleShop`,
            category: 'shop',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    // 業務
    {
      path: 'business',
      component: () => import('@/views/memberCenter/page/common/business/BusinessLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyBusinessHome',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
            ],
            title: 'breadcrumbList.businessManagement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        // 行程
        {
          path: 'schedule',
          name: 'companyBusinessSchedule',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.schedule',
                pathName: 'companyBusinessSchedule',
              },
            ],
            title: 'memberCenter.schedule',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        // 報表
        {
          path: 'report',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          children: [
            {
              path: '',
              name: 'companyBusinessReport',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.performanceReport',
                    pathName: 'companyBusinessReport',
                  },
                ],
                title: 'breadcrumbList.performanceReport',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'monthly',
              name: 'companyBusinessReportMonthly',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.performanceReport',
                    pathName: 'companyBusinessReportMonthly',
                  },
                ],
                title: 'breadcrumbList.performanceReport',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'activity',
              name: 'companyBusinessReportActivity',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.performanceReport',
                    pathName: 'companyBusinessReportActivity',
                  },
                ],
                title: 'breadcrumbList.performanceReport',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
          ],
        },
        // 秘書管理
        {
          path: 'secretary',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          props: true,
          children: [
            {
              path: '',
              name: 'companyBusinessSecretary',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.secretaryManagement',
                    pathName: 'companyBusinessSecretary',
                  },
                ],
                title: 'breadcrumbList.performanceReport',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
          ],
        },
        // 任務歷程
        {
          path: 'report/sales/:id',
          name: 'companyBusinessReportSales',
          component: () => import('@/views/memberCenter/page/common/business/page/history/BusinessHistory.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.performanceReport',
                pathName: 'companyBusinessReport',
              },
              {
                name: 'memberCenter.missionHistory',
                pathName: 'companyBusinessReportSales',
              },
            ],
            title: 'memberCenter.missionHistory',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'report/add',
          name: 'companyBusinessAddReport',
          component: () => import('@/views/memberCenter/page/common/business/page/new_goal/BusinessAddGoal.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.addInBatches',
                pathName: 'companyBusinessAddReport',
              },
            ],
            title: 'memberCenter.addInBatches',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        // 客戶
        {
          path: 'client-management',
          component: () => import('@/views/memberCenter/page/common/business/page/client/BusinessClient.vue'),
          children: [
            {
              path: '',
              name: 'companyBusinessClient',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                ],
                title: 'memberCenter.clientManagement',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'client',
              name: 'companyBusinessClientClients',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClientClients',
                  },
                ],
                title: 'memberCenter.clientManagement',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'vip',
              name: 'companyBusinessVip',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClientClients',
                  },
                  {
                    name: 'memberCenter.vip',
                    pathName: 'companyBusinessVip',
                  },
                ],
                title: 'memberCenter.clientManagement',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
          ],
        },
        {
          path: 'client-management/audit',
          component: () => import('@/views/memberCenter/page/common/business/page/audit/BusinessAudit.vue'),
          children: [
            {
              path: '',
              name: 'companyBusinessClientAuditEntrust',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClientClients',
                  },
                  {
                    name: 'memberCenter.entrustedAudit',
                    pathName: 'companyBusinessClientAuditEntrust',
                  },
                ],
                title: 'memberCenter.entrustedAudit',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: false,
                requiredAuth: true,
              },
            },
            {
              path: 'client',
              name: 'companyBusinessClientAuditEntrustClients',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.entrustedAudit',
                    pathName: 'companyBusinessClientAuditEntrustClients',
                  },
                ],
                title: 'memberCenter.entrustedAudit',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: false,
                requiredAuth: true,
              },
            },
            {
              path: 'contract',
              name: 'companyBusinessClientAuditContract',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.entrustedAudit',
                    pathName: 'companyBusinessClientAuditEntrust',
                  },
                  {
                    name: 'memberCenter.contractAudit',
                    pathName: 'companyBusinessClientAuditContract',
                  },
                ],
                title: 'memberCenter.contractAudit',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: false,
                requiredAuth: true,
              },
            },
            {
              path: 'contract/client',
              name: 'companyBusinessClientAuditContractClients',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.entrustedAudit',
                    pathName: 'companyBusinessClientAuditEntrust',
                  },
                  {
                    name: 'memberCenter.contractAudit',
                    pathName: 'companyBusinessClientAuditContractClients',
                  },
                ],
                title: 'memberCenter.contractAudit',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: false,
                requiredAuth: true,
              },
            },
          ],
        },
        {
          path: 'client-management/new-client',
          name: 'companyBusinessClientNew',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClient.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClientClients',
              },
              {
                name: 'memberCenter.addNewClient',
                pathName: 'companyBusinessClientNew',
              },
            ],
            title: 'memberCenter.addNewClient',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/new-client/:type',
          name: 'companyBusinessClientNewInfo',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClientForm.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClientClients',
              },
              {
                name: 'memberCenter.addNewClient',
                pathName: 'companyBusinessClientNewInfo',
              },
            ],
            title: 'memberCenter.addNewClient',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/:type/:id',
          name: 'companyBusinessEditClient',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClientForm.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClient',
              },
              {
                name: 'memberCenter.editClient',
                pathName: 'companyBusinessEditClient',
              },
            ],
            showEditBtn: false,
            title: 'memberCenter.editClient',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/visit/:id',
          name: 'companyBusinessVisitClient',
          component: () => import('@/views/memberCenter/page/common/business/page/visit/BusinessVisit.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClient',
              },
              {
                name: 'memberCenter.visitRecord',
                pathName: 'companyBusinessVisitClient',
              },
            ],
            title: 'memberCenter.visitRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/entrustment-record/:memberId',
          component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/EntrustmentRecordLayout.vue'),
          children: [
            {
              path: ':category',
              props: true,
              children: [
                {
                  path: '',
                  name: 'companyBusinessEntrustmentRecordPublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/publish/PublishView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                    ],
                    title: 'memberCenter.entrustmentRecord',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'single',
                  name: 'companyBusinessEntrustmentRecordSinglePublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/publish/PublishView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.singlePosting',
                        pathName: 'companyBusinessEntrustmentRecordSinglePublish',
                      },
                    ],
                    title: 'memberCenter.singlePosting',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'rental',
                  name: 'companyBusinessEntrustmentRecordRentalPublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/publish/PublishView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.rentalPlanPublication',
                        pathName: 'companyBusinessEntrustmentRecordRentalPublish',
                      },
                    ],
                    title: 'memberCenter.rentalPlanPublication',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'sale',
                  name: 'companyBusinessEntrustmentRecordSalePublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/publish/PublishView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.salePlanPublication',
                        pathName: 'companyBusinessEntrustmentRecordSalePublish',
                      },
                    ],
                    title: 'memberCenter.salePlanPublication',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'closed',
                  name: 'companyBusinessEntrustmentRecordClosedPublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/closed/ClosedView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.housesClosed',
                        pathName: 'companyBusinessEntrustmentRecordClosedPublish',
                      },
                    ],
                    title: 'memberCenter.housesClosed',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'deal',
                  name: 'companyBusinessEntrustmentRecordDealPublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/deal/DealView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.removedHouses',
                        pathName: 'companyBusinessEntrustmentRecordDealPublish',
                      },
                    ],
                    title: 'memberCenter.removedHouses',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
                {
                  path: 'edit',
                  name: 'companyBusinessEntrustmentRecordEditPublish',
                  component: () => import('@/views/memberCenter/page/common/business/page/entrustment_record/edit/EditView.vue'),
                  meta: {
                    breadcrumbs: [
                      {
                        name: 'breadcrumbList.home',
                        pathName: 'home',
                      },
                      {
                        name: 'breadcrumbList.memberHome',
                        pathName: 'companyHome',
                      },
                      {
                        name: 'breadcrumbList.businessManagement',
                        pathName: 'companyBusinessHome',
                      },
                      {
                        name: 'memberCenter.clientManagement',
                        pathName: 'companyBusinessVip',
                      },
                      {
                        name: 'memberCenter.entrustmentRecord',
                        pathName: 'companyBusinessEntrustmentRecordPublish',
                      },
                      {
                        name: 'memberCenter.editHouse',
                        pathName: 'companyBusinessEntrustmentRecordEditPublish',
                      },
                    ],
                    title: 'memberCenter.editHouse',
                    description: 'about.description1',
                    image: metaBg,
                    showEditBtn: false,
                    requiredAuth: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'client-management/entrustment-record/:memberId/:category/:type/:id',
          name: 'companyBusinessEntrustmentRecordPublishDetail',
          component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
          props: true,
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessVip',
              },
              {
                name: 'memberCenter.entrustmentRecord',
                pathName: 'companyBusinessEntrustmentRecordPublish',
              },
              {
                name: ':id',
                pathName: 'companyBusinessEntrustmentRecordPublishDetail',
              },
            ],
            title: 'memberCenter.entrustmentRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/entrustment-record/:memberId/:category/closed/:type/:id',
          name: 'companyBusinessEntrustmentRecordPublishClosedDetail',
          component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
          props: true,
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessVip',
              },
              {
                name: 'memberCenter.entrustmentRecord',
                pathName: 'companyBusinessEntrustmentRecordPublish',
              },
              {
                name: 'memberCenter.housesClosed',
                pathName: 'companyBusinessEntrustmentRecordClosedPublish',
              },
              {
                name: ':id',
                pathName: 'companyBusinessEntrustmentRecordPublishClosedDetail',
              },
            ],
            title: 'memberCenter.entrustmentRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/entrustment-record/:memberId/:category/deal/:type/:id',
          name: 'companyBusinessEntrustmentRecordPublishDealDetail',
          component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
          props: true,
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessVip',
              },
              {
                name: 'memberCenter.entrustmentRecord',
                pathName: 'companyBusinessEntrustmentRecordPublish',
              },
              {
                name: 'memberCenter.removedHouses',
                pathName: 'companyBusinessEntrustmentRecordDealPublish',
              },
              {
                name: ':id',
                pathName: 'companyBusinessEntrustmentRecordPublishDealDetail',
              },
            ],
            title: 'memberCenter.entrustmentRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'branch',
          name: 'companyBusinessBranch',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClient',
              },
              {
                name: 'memberCenter.branchList',
                pathName: 'companyBusinessBranch',
              },
            ],
            title: 'memberCenter.branchList',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'branch/review',
          name: 'companyBusinessBranchReview',
          component: () => import('@/views/memberCenter/page/common/business/page/branch_audit/BusinessBranchAudit.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClient',
              },
              {
                name: 'memberCenter.branchList',
                pathName: 'companyBusinessBranch',
              },
              {
                name: 'memberCenter.applicationRecords',
                pathName: 'companyBusinessBranchReview',
              },
            ],
            title: 'memberCenter.applicationRecords',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'branch/reply',
          name: 'companyBusinessBranchReply',
          component: () => import('@/views/memberCenter/page/common/business/page/branch_audit/BusinessBranchAudit.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClient',
              },
              {
                name: 'memberCenter.branchList',
                pathName: 'companyBusinessBranch',
              },
              {
                name: 'memberCenter.applicationRecords',
                pathName: 'companyBusinessBranchReview',
              },
              {
                name: 'memberCenter.waitingForReply',
                pathName: 'companyBusinessBranchReply',
              },
            ],
            title: 'memberCenter.waitingForReply',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'branch/:id',
          component: () => import('@/views/memberCenter/page/common/business/page/branch_detail/BranchDetailHome.vue'),
          children: [
            {
              path: '',
              name: 'companyBusinessBranchDetailHome',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.branchList',
                    pathName: 'companyBusinessBranch',
                  },
                  {
                    name: 'memberCenter.branchDetail',
                    pathName: 'companyBusinessBranchDetailHome',
                  },
                ],
                title: 'memberCenter.branchDetail',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'tasks',
              name: 'companyBusinessBranchDetailTasks',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.branchList',
                    pathName: 'companyBusinessBranch',
                  },
                  {
                    name: 'memberCenter.branchDetail',
                    pathName: 'companyBusinessBranchDetailTasks',
                  },
                ],
                title: 'memberCenter.branchDetail',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'monthly',
              name: 'companyBusinessBranchDetailHomeMonthly',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.branchList',
                    pathName: 'companyBusinessBranch',
                  },
                  {
                    name: 'memberCenter.branchDetail',
                    pathName: 'companyBusinessBranchDetailMonthly',
                  },
                ],
                title: 'memberCenter.branchDetail',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'activity',
              name: 'companyBusinessBranchDetailHomeActivity',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'companyHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'companyBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'companyBusinessClient',
                  },
                  {
                    name: 'memberCenter.branchList',
                    pathName: 'companyBusinessBranch',
                  },
                  {
                    name: 'memberCenter.branchDetail',
                    pathName: 'companyBusinessBranchDetailActivity',
                  },
                ],
                title: 'memberCenter.branchDetail',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
          ],
        },
        {
          path: 'secretary/:id',
          name: 'companyBusinessSecretaryDetail',
          component: () => import('@/views/memberCenter/page/common/business/page/secretary/BusinessSecretary.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.secretaryManagement',
                pathName: 'companyBusinessSecretary',
              },
              {
                name: 'memberCenter.commissionHistory',
                pathName: 'companyBusinessSecretaryDetail',
              },
            ],
            title: 'memberCenter.commissionHistory',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/secretary-assignment',
          name: 'companyBusinessSecretaryAssignment',
          component: () => import('@/views/memberCenter/page/common/business/page/audit/BusinessAudit.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'companyBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'companyBusinessClientClients',
              },
              {
                name: 'memberCenter.secretaryAssignment',
                pathName: 'companyBusinessSecretaryAssignment',
              },
            ],
            title: 'memberCenter.secretaryAssignment',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
      ],
    },
    // 店鋪
    {
      path: 'store',
      component: () => import('@/views/memberCenter/page/common/store/StoreLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyStore',
          component: () => import('@/views/memberCenter/page/common/store/storeInfo/StoreView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.storeManagement',
                pathName: 'companyStore',
              },
            ],
            title: 'memberCenter.storeManagement',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'flyers',
          name: 'companyStoreFlyers',
          component: () => import('@/views/memberCenter/page/common/store/storeFlyers/StoreFlyers.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.flyers',
                pathName: 'companyStoreFlyers',
              },
            ],
            title: 'memberCenter.flyers',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
      ],
    },
    // 紀錄
    {
      path: 'history',
      component: () => import('@/views/memberCenter/page/common/history/HistoryLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyHistory',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'companyHistory',
              },
            ],
            title: 'breadcrumbList.history',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'favorite',
          name: 'companyHistoryFavorite',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'companyHistory',
              },
              {
                name: 'breadcrumbList.favoriteObjects',
                pathName: 'companyHistoryFavorite',
              },
            ],
            title: 'breadcrumbList.favoriteObjects',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    // 推廣
    {
      path: 'promotions',
      component: () => import('@/views/memberCenter/page/common/promotions/PromotionsLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyPromotions',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'companyPromotions',
              },
            ],
            title: 'breadcrumbList.promotions',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: ':id',
          name: 'companyPromotionsDetail',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsDetail.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'companyPromotions',
              },
              {
                name: 'breadcrumbList.activityDetails',
                pathName: 'companyPromotionsDetail',
              },
            ],
            title: 'breadcrumbList.activityDetails',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
      ],
    },
    // 交易
    {
      path: 'transaction',
      component: () => import('@/views/memberCenter/page/common/transaction/TransactionLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyTransaction',
          component: () => import('@/views/memberCenter/page/common/transaction/point/TransactionView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.transaction',
                pathName: 'companyTransaction',
              },
            ],
            title: 'breadcrumbList.transaction',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'plan',
          name: 'companyTransactionOfPlan',
          component: () => import('@/views/memberCenter/page/common/transaction/plan/PlanView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.plansManagement',
                pathName: 'companyTransactionOfPlan',
              },
            ],
            title: 'breadcrumbList.plansManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'cash',
          name: 'companyTransactionOfCash',
          component: () => import('@/views/memberCenter/page/common/transaction/cash/CashView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.cashManagement',
                pathName: 'companyTransactionOfCash',
              },
            ],
            title: 'breadcrumbList.cashManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'upgrade',
          name: 'companyTransactionOfUpgrade',
          component: () => import('@/views/memberCenter/page/common/transaction/upgrade/PlanView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'memberCenter.upgradeManagement',
                pathName: 'companyTransactionOfUpgrade',
              },
            ],
            title: 'memberCenter.upgradeManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
            scrollAnchor: true,
          },
        },
        // TODO: 信用卡先隱藏
        // {
        //   path: 'credit-card',
        //   name: 'companyCreditCard',
        //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/CreditCard.vue'),
        //   meta: {
        //     breadcrumbs: [
        //       {
        //         name: 'breadcrumbList.home',
        //         pathName: 'home',
        //       },
        //       {
        //         name: 'breadcrumbList.paymentManagement',
        //         pathName: 'companyCreditCard',
        //       },
        //     ],
        //     showBtn: false,
        //   },
        // },
      ],
    },
    {
      path: 'transaction/purchase/:type/:id',
      name: 'companyPurchasePlan',
      component: () => import('@/views/memberCenter/page/common/transaction/PurchasePlan.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.plansManagement',
            pathName: 'companyTransactionOfPlan',
          },
          {
            name: 'breadcrumbList.purchasePlan',
            pathName: 'companyPurchasePlan',
          },
        ],
        title: 'breadcrumbList.purchasePlan',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
    // TODO: 信用卡先隱藏
    // {
    //   path: 'credit-card/add',
    //   name: 'companyAddCreditCard',
    //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/AddCreditCard.vue'),
    //   meta: {
    //     breadcrumbs: [
    //       {
    //         name: 'breadcrumbList.home',
    //         pathName: 'home',
    //       },
    //       {
    //         name: 'breadcrumbList.paymentManagement',
    //         pathName: 'companyCreditCard',
    //       },
    //       {
    //         name: 'breadcrumbList.addNewCard',
    //         pathName: 'companyAddCreditCard',
    //       },
    //     ],
    //     showBtn: false,
    //   },
    // },
    {
      path: 'advertisement',
      component: () => import('@/views/memberCenter/page/common/advertisement/AdLayout.vue'),
      children: [
        {
          path: '',
          name: 'companyAdvertisement',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisement',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: 'companyAdvertisementClosed',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementClosed',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'application',
          name: 'companyAdvertisementApplication',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementApplication',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/approve',
          name: 'companyAdvertisementApplicationApprove',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementApplicationApprove',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/review',
          name: 'companyAdvertisementApplicationReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementApplicationReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/not-reviewed',
          name: 'companyAdvertisementApplicationNotReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementApplicationNotReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/decline',
          name: 'companyAdvertisementApplicationDecline',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementApplicationDecline',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/plan',
          name: 'companyAdvertisementPlan',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'companyHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'companyAdvertisementPlan',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'advertisement/apply/:id',
      name: 'companyAdvertisementApply',
      component: () => import('@/views/memberCenter/page/common/advertisement/apply/ApplyView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'companyHome',
          },
          {
            name: 'breadcrumbList.advertisement',
            pathName: 'companyAdvertisement',
          },
          {
            name: 'breadcrumbList.applyForAds',
            pathName: 'companyAdvertisementApply',
          },
        ],
        title: 'breadcrumbList.applyForAds',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
  ],
};
export default companyRouter;
