import { createRouter, createWebHistory } from 'vue-router';
import Layout from '../views/Layout.vue';
import HomeView from '../views/home/HomeView.vue';
import rentRouter from './modules/rent';
import saleRouter from './modules/sale';
import preSaleRouter from '@/router/modules/preSale';
import factoryRouter from '@/router/modules/factory';
import landRouter from '@/router/modules/land';
import officeRouter from '@/router/modules/office';
import shopRouter from '@/router/modules/shop';
import mapRentRouter from './modules/rent_map';
import mapSaleRouter from './modules/sale_map';
import mapPreSaleRouter from '@/router/modules/preSale_map';
import mapLandRouter from '@/router/modules/land_map';
import mapOfficeRouter from '@/router/modules/office_map';
import mapFactoryRouter from '@/router/modules/factory_map';
import mapShopRouter from '@/router/modules/shop_map';
import rentDetailRouter from './modules/rent_detail';
import saleDetailRouter from './modules/sale_detail';
import preSaleDetailRouter from '@/router/modules/preSale_detail';
import factoryRentDetailRouter from '@/router/modules/factory_rent_detail';
import factorySaleDetailRouter from '@/router/modules/factory_sale_detail';
import landRentDetailRouter from '@/router/modules/land_rent_detail';
import landSaleDetailRouter from '@/router/modules/land_sale_detail';
import officeRentDetailRouter from '@/router/modules/office_rent_detail';
import officeSaleDetailRouter from '@/router/modules/office_sale_detail';
import shopRentDetailRouter from '@/router/modules/shop_rent_detail';
import shopSaleDetailRouter from '@/router/modules/shop_sale_detail';
import publisherRouter from './modules/publisher';
import aboutRouter from './modules/about';
import planRouter from './modules/plan';
import upgradeAccountRouter from './modules/upgrade_account_plan';
import videoRouter from './modules/video';
import disclaimerRouter from './modules/disclaimer';
import policyRouter from './modules/policy';
import loginRouter from './modules/login';
import identityRouter from './modules/identity';
import registerRouter from './modules/register';
import forgetPwdRouter from './modules/forget_password';
import publishRouter from './modules/publish';
import memberRouter from './modules/member_center';
import forumRouter from './modules/forum';
import mortgageCalculatorRouter from './modules/mortgage_calculator';
import filesRouter from '@/router/modules/files';
import metaBg from '@/assets/meta/meta_bg.png';

const routes = [
  {
    path: '/:language?',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          title: 'title',
          description: 'about.description1',
          image: metaBg,
          keepAlive: true,
          componentName: 'HomeView',
        },
      },
      rentRouter,
      mapRentRouter,
      rentDetailRouter,
      saleRouter,
      preSaleRouter,
      factoryRouter,
      landRouter,
      officeRouter,
      shopRouter,
      mapSaleRouter,
      saleDetailRouter,
      preSaleDetailRouter,
      factoryRentDetailRouter,
      factorySaleDetailRouter,
      mapFactoryRouter,
      landRentDetailRouter,
      landSaleDetailRouter,
      officeRentDetailRouter,
      officeSaleDetailRouter,
      shopRentDetailRouter,
      shopSaleDetailRouter,
      mapLandRouter,
      mapPreSaleRouter,
      mapOfficeRouter,
      mapShopRouter,
      publisherRouter,
      aboutRouter,
      planRouter,
      upgradeAccountRouter,
      disclaimerRouter,
      policyRouter,
      loginRouter,
      identityRouter,
      registerRouter,
      forgetPwdRouter,
      publishRouter,
      memberRouter,
      forumRouter,
      videoRouter,
      mortgageCalculatorRouter,
      filesRouter,
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.meta.scrollAnchor) {
      const anchor = document.querySelector('#publish-plan');
      if (anchor) {
        return { top: anchor.offsetTop };
      }
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.name === 'video' || to.name === 'forumAllArticlesOnHome') {
      return savedPosition;
    }
    if (to.name.indexOf('Map') !== -1) {
      if (Object.keys(to.query).length > 0) {
        return { top: 279 };
      }
    }
    return { top: 0 };
  },
});

export default router;
