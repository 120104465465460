const zhCn = {
  title: '88go house找房网',
  description: '88go house找房网 - 找到你的家!',
  breadcrumbList: {
    home: '首页',
    rent: '出租',
    sale: '出售',
    preSale: '新建案',
    factory: '厂房',
    land: '土地',
    mapSearch: '地图搜寻',
    choose88go: '为何选择88go',
    ourPlan: '我们的方案',
    upgradeAccount: '帐户升级方案',
    agent: '代理规章',
    disclaimer: '免责声明',
    policy: '会员条款与隐私权声明',
    publish: '我要刊登',
    memberHome: '会员首页',
    account: '账号管理',
    changePhone: '手机号码变更',
    changePwd: '修改密码',
    message: '讯息管理',
    messageContent: '讯息内容',
    houseQA: '房屋问答',
    impeach: '检举',
    history: '历史纪录',
    favoriteObjects: '喜爱的物件',
    transactionRecords: '房屋交易纪录',
    promotions: '推广活动',
    activityDetails: '活动详情',
    performanceReport: '业绩报表',
    accountManagement: '帐户管理',
    addAccount: '新增帐户',
    transaction: '交易管理',
    plansManagement: '方案管理',
    cashManagement: '金流管理',
    purchasePlan: '购买方案',
    paymentManagement: '付款管理',
    addNewCard: '新增卡片',
    publishManagement: '刊登管理',
    housesClosed: '关闭中',
    housesEdit: '未刊登',
    removedHouses: '成交下架',
    renew: '我要续刊',
    singlePosting: '单笔刊登',
    rentalPlanPublication: '出租方案刊登',
    salePlanPublication: '出售方案刊登',
    editListing: '编辑刊登',
    improveEffect: '提升效果',
    storeManagement: '店铺管理',
    storeQA: '店铺问答',
    flyers: '传单制作',
    improve: '提升效果',
    certification: '资格认证',
    workData: '工作资料',
    subAccount: '子账号管理',
    advertisement: '广告管理',
    applyForAds: '申请广告刊登',
    forum: '讨论区',
    myArticles: '我的文章',
    publishArticle: '发表文章',
    editArticle: '编辑文章',
    myRequirements: '我的需求',
    advertisementApplicationRecord: '广告申请记录',
    video: '视频专区',
    businessManagement: '业务管理',
  },
  navbar: {
    publish: '我要刊登',
    rent: '租屋',
    sale: '购屋',
    preSale: '新建案',
    factory: '厂房',
    land: '土地',
    house: '住宅',
    office: '办公',
    shop: '店铺',
    register: '注册',
    login: '登录',
    memberCenter: '会员中心',
    signOut: '登出',
    hello: '您好',
    member: '会员',
    forum: '讨论区',
    video: '视频专区',
    join: '账户',
    objectType: '物件类型',
  },
  login: {
    phoneNumber: '手机号码',
    phonePlaceholder: '请输入手机号码',
    pwd: '密码',
    pwdplaceholder: '请输入密码8至12位包含英数字(不含大寫)',
    remember: '记住我',
    forget: '忘记密码',
    login: '登录',
    noAccount: '还没有账号吗？',
    register: '注册',
    accountLogin: '以社群账号登入',
    socialAccountNotice: '社群账号登入需至会员中心绑定方可使用',
  },
  register: {
    registrationStatus: '请选择您的注册身份',
    buyer: '买家/房客',
    choose: '选择',
    owner: '屋主/代理人',
    estateAgent: '经理人/房屋仲介',
    estateAgency: '仲介公司',
    social: '以社群账号注册',
    hostWarningText: '*合法经纪业人士不得使用「屋主/代理人]身份注册',
    agentWarningText: '*请注明经纪业名称，非合法经纪业人员不得于88go执行业务',
    emergencyText: '如遇账号问题/广告讯息确认/网站活动等情况，88go无法联络会员时，得通知紧急联络人',
    hasAccount: '已经有账号了吗？',
    login: '登录',
    phoneNumber: '手机号码',
    phonePlaceholder: '请输入手机号码',
    pwd: '密码',
    pwdplaceholder: '请输入密码8至12位包含英数字(不含大寫)',
    confirmPwd: '确认密码',
    name: '姓名',
    nameplaceholder: '请输入姓名',
    emailplaceholder: '请输入Email',
    gender: '性别',
    male: '男',
    female: '女',
    ready: '我已阅读并了解',
    terms: '会员条款与隐私权声明',
    and: '与',
    disclaimer: '免责声明',
    ready2: '等所载内容以及其意义，兹同意该等条款规定，并愿遵守网站现金、嗣后规范的各种规则',
    register: '注册',
    accountOwnership: '账号归属',
    companyAccount: '集团账号',
    branchAccount: '分店账号',
    other: '其他',
    workArea: '工作地区',
    company: '所属公司',
    branch: '分店',
    position: '职位',
    brokerageName: '经纪业名称',
    brokerageNameplaceholder: '请输入经纪业名称',
    storeType: '店面型态',
    directSale: '直営店',
    franchise: '加盟店',
    invoice: '发票',
    invoiceType: {
      donate: '捐给慈善机构',
      donateAlert: '*捐出将无法再索取',
      donateDes: '(受赠机关或团体:青埔教育基金会)',
      send: '对中寄送',
      sendDes: '(每逢单月26号进行兑奖作业，若中奖，会以简讯通知，并以挂号寄出)',
      tax: '编号',
      taxAlert: '*务必仔细填写，以免寄失',
      taxDes: '(以平信寄出，邮寄时间较长)',
      receipt: '电子发票/手机载具',
      receiptDes: '(依据『消费通路开立电子发票试办作业要点』开立)',
      buyer: '买受人',
      buyerplaceholder: '请输入姓名',
      contactPhone: '联络电话',
      contactPhonePlaceholder: '请输入联络电话',
      addressplaceholder: '请输入完整的收件地址',
      taxplaceholder: '请输入编号',
      mobileBarcode: '手机载具条码',
      mobileBarcodeplaceholder: '请输入手机载具条码',
      confirmMobileBarcode: '确认手机载具条码',
    },
    next: '下一步',
    emergencyIdentity: '紧急联络人身份',
    emergencyIdentityplaceholder: '请选择紧急联络人身份',
    emergencyName: '紧急联络人姓名',
    emergencyNameplaceholder: '请输入紧急联络人姓名',
    emergencyNumber: '紧急联络人手机号码',
    notice: '注意事项：<br>1.紧急联络人仅为通知会员的一种渠道，无法代理会员行使账号权利。 <br>2.通知紧急联络人视为已通知会员，故联络资讯若有变动，应即时更新。若紧急联络人有拒绝接听、未协助通知会员或联络不上等， 致会员未收到通知之情形，与88go无涉，会员须自行负责。',
  },
  fogotPwd: {
    enterPhone: '请在下方输入您的手机号码',
    phoneNumber: '手机号码',
    phonePlaceholder: '请输入手机号码',
    next: '下一步',
  },
  verify: {
    sendCode: '已发送4位验证码至',
    verify: '验证',
    notReceive: '没有收到验证码吗？',
    resendCode: '重新发送验证码',
    verifySuccess: '手机验证成功，将跳转至修改密码页面',
    confirm: '确认',
    thxToRegister: '感谢注册成为88go会员,您的手机号码已验证成功!',
    socialMedia: '是否前往会员中心进行社群账号快速登入绑定？',
    loginAgain: '重新登录',
    goFillIn: '是，前往绑定',
    goHome: '否，前往首页',
  },
  home: {
    slogan: '让我们为您寻找舒适的',
    idelHome: '理想家居',
    saleSlogan: '实现',
    homeDream: '家的梦想',
    saleSlogan2: '由此开始',
    newHouseSlogan: '享受全新生活，迎接',
    newHouseSlogan2: '美好未来',
    keywordPlaceholder: '请输入关键字',
    area: '地区',
    houseType: '房屋类型',
    housingLayout: '房屋格局',
    sort: '排序',
    preferredHouse: '优选房屋',
    preferredConstruction: '优选建案',
    preferredFactory: '优选厂房',
    preferredLand: '优选土地',
    preferredOffice: '优选办公',
    preferredShop: '优选店铺',
    suitable: '适合您的房屋',
    suitableNewHouse: '适合您的新建案',
    suitableFactory: '适合您的厂房',
    suitableLand: '适合您的土地',
    suitableOffice: '适合您的办公',
    suitableShop: '适合您的店铺',
    more: '查看更多',
    rentInPopular: '热门地区租房',
    buyingPopular: '热门地区购屋',
    newHousePopular: '热门地区建案',
    factoryPopular: '热门地区厂房',
    landPopular: '热门地区土地',
    officePopular: '热门地区办公',
    shopPopular: '热门地区店铺',
    listSearch: '列表搜寻',
    mapSearch: '地图搜寻',
    news: '媒体新闻',
    learnMoreAbout88go: '了解88go',
    aboutDes: '我们是一个提供全方位居住解决方案的平台，无论你的居住需求如何，88go 都将成为你可靠的伙伴。我们期待在这个居住的旅程中，为你提供最优质的服务。与你携手实现居住的新未来。',
    multipleListing: '多房源',
    listingDes: '擁有众多优质的房源，涵盖各种需求和预算',
    noFee: '免仲介费',
    noFeeDes: '不再让繁琐的仲介费用成为你选屋的障碍',
    convenientTransactions: '交易方便',
    convenientTransactionsDes: '透明的购买流程，轻松愉快的租售房屋',
    whyChoose: '为何选择88go',
    outPlan: '我們的方案',
    filter: '筛选条件',
    rent: '租金',
    sale: '售价',
    minimumPrice: '请输入最低价格',
    maximumPrice: '请输入最高价格',
    proportion: '面积',
    minimumArae: '请输入最低面积',
    maximumArae: '请输入最高面积',
    specialRequirment: '特殊需求',
    search: '搜索',
    clearFilter: '清除筛选',
    gender: '性別',
    maleOnly: '限男性',
    femaleOnly: '限女性',
    identity: '身份',
    choice: '精选',
    urgent: '急',
    searchObject: '不知道从何开始寻屋吗？',
    tellUs: '告诉我们您的需求',
    explainYourNeed: '说明您的需求，88go为您智慧寻找梦想中的完美物件',
    rentHouseQuestion1: '您目前的租屋预算？',
    rentHouseQuestion1Des: '请输入您的预算区间，您的资料将会保密',
    minimumBudget: '最低预算',
    maximumBudget: '最高预算',
    rentHouseQuestion2: '您目前偏好的房屋类型？',
    rentHouseQuestion2Des: '可选择至多三个选项，您的资料将会保密',
    prev: '上一步',
    rentHouseQuestion3: '您目前偏好的房屋型态？',
    minRoom: '最低房间数量',
    maxRoom: '最高房间数量',
    rentHouseQuestion4: '您目前偏好的格局？',
    rentHouseQuestion4Des: '请输入您的理想房间数量，您的资料将会保密',
    squareQuestion: '您目前偏好的面积？',
    squareQuestionDes: '请输入您的理想面积区间，您的资料将会保密',
    rentHouseQuestion5: '您目前偏好的地区？',
    rentHouseQuestion5es: '请选择您的理想偏好的地区，您的资料将会保密',
    smartHouseSearch: '智慧寻屋',
    loginRequire: '此功能需登录后使用',
    rentalHouseConditions: '您理想的租屋条件',
    reset: '重新设置',
    preferenceLayout: '偏好格局',
    preferenceArea: '偏好地区',
    filledInOn: '填写于',
    saleHouseQuestion1: '您目前的购屋预算？',
    purchaseHouseConditions: '您理想的购屋条件',
    newHouseConditions: '您理想的新建案条件',
    newHouseQuestion2: '您目前偏好的推案状态？',
    factoryRentQuestion1: '您目前的厂房承租预算？',
    factorySaleQuestion1: '您目前的厂房购买预算？',
    factoryQuestion2: '您目前偏好的厂房型态？',
    factoryConditions: '您理想的厂房条件',
    landRentQuestion1: '您目前的土地承租预算？',
    landSaleQuestion1: '您目前的土地购买预算？',
    landQuestion2: '您目前偏好的土地型态？',
    landConditions: '您理想的土地条件',
    officeRentQuestion1: '您目前的办公承租预算？',
    officeSaleQuestion1: '您目前的办公购买预算？',
    officeQuestion2: '您目前偏好的办公类型？',
    officeQuestion3: '您目前偏好的办公型态？',
    officeConditions: '您理想的办公条件',
    shopRentQuestion1: '您目前的店铺承租预算？',
    shopSaleQuestion1: '您目前的店铺购买预算？',
    shopQuestion2: '您目前偏好的店铺类型？',
    shopQuestion3: '您目前偏好的店铺型态？',
    shopConditions: '您理想的店铺条件',
    idealSlogan: '在下方查看88go为您推荐的理想房屋！',
    rentTab: '我想承租',
    saleTab: '我想购买',
    feature: '此功能仅限买家/房客身份',
    noResult: '目前没有符合您需求的物件，您可以重新设置需求或参考我们推荐的其他房屋。若有适合您的房屋上架，我们将会尽快通知您。',
  },
  footer: {
    aboutUs: '关于我们',
    resource: '资源',
    listSearch: '列表搜寻',
    mapSearch: '地图搜寻',
    whyChoose: '为何选择88go',
    ourPlan: '我们的方案',
    agentRegulations: '代理规章',
    disclaimer: '免责声明',
    termsOfMembershipAndPrivacyStatement: '会员条款与隐私权声明',
    mortgageCalculator: '房贷试算器',
  },
  rent: {
    title: '租屋列表',
    enTitle: 'Rental List',
    mapSearch: '地图搜寻',
    recommended: '推荐房屋',
    forRent: '所有待租房屋',
    forSale: '所有在售房屋',
  },
  sale: {
    title: '购屋列表',
    enTitle: 'Sale List',
  },
  preSale: {
    title: '新建案列表',
    enTitle: 'New Project List',
    recommended: '推荐建案',
    forPreSale: '所有建案',
    determined: '价钱待定',
  },
  factoryList: {
    title: '厂房列表',
    enTitle: 'Factory List',
    recommended: '推荐厂房',
    forFactory: '所有厂房',
    factoryForRent: '厂房出租',
    factoryForSale: '厂房出售',
  },
  landList: {
    title: '土地列表',
    enTitle: 'Land List',
    recommended: '推荐土地',
    forLand: '所有土地',
    landForRent: '土地出租',
    landForSale: '土地出售',
  },
  officeList: {
    title: '办公列表',
    enTitle: 'Office List',
    recommended: '推荐办公',
    forOffice: '所有办公',
    officeForRent: '办公出租',
    officeForSale: '办公出售',
  },
  shopList: {
    title: '店铺列表',
    enTitle: 'Shop List',
    recommended: '推荐店铺',
    forShop: '所有店铺',
    shopForRent: '店铺出租',
    shopForSale: '店铺出售',
  },
  video: {
    title: '視頻专区',
    enTitle: 'Videos',
    home: '视频专区首页',
    favorite: '收藏的视频',
    latest: '最新视频',
    popular: '热门视频',
    videoType: '视频类别',
    search: '搜寻视频标题',
    views: '查看数',
    send: '发送',
    showAllComment: '显示完整回复',
    showLessComment: '只显示部分内容',
    replies: '则回复',
  },
  search: {
    sort: '排序',
    order: {
      1: '时间由近到远',
      2: '时间由远到近',
      3: '价格由低到高',
      4: '价格由高到低',
      5: '面积由小到大',
      6: '面积由大到小',
    },
    addr: '地区',
    city: '省、县市',
    area: '區域 (行政區)',
    ward: '鄉、鎮、社、坊 (里)',
    type: '类型',
    pattern: '型态',
    rentRange: '租金范围',
    saleRange: '售价范围',
    unitRange: '单价范围',
    totalRange: '总价范围',
    structure: '格局',
    proportion: '面积',
    houseDirection: '房子方向',
    floor: '楼层',
    special: '特殊需求',
    identity: '身份要求',
    unlimited: '不限',
    // bedsit: '雅房',
    // doubleRoom: '分租套房',
    // studio: '独立套房',
    // wholeFloor: '整層住家',
    // apartment: '公寓',
    // condo: '电梯大楼',
    // house: '透天厝',
    // vila: '别墅',
    room: '房',
    roomUp: '房以上',
    squareMeters: 'm2',
    expand: '展开选项',
    collapse: '收起选项',
    // eastWest: '东西向',
    // northSouth: '南北向',
    // SouthWest: '西南向',
    // northEast: '东北向',
    // northWest: '西北向',
    // southEast: '东南向',
    nearMRT: '近捷运',
    pet: '可养宠物',
    furnace: '可开伙',
    parking: '附车位',
    elevator: '有电梯',
    balcony: '有阳台',
    shortTerm: '提供短期租赁',
    hostPublished: '屋主刊登',
    maleOnly: '限男性',
    femaleOnly: '限女性',
    officeWorker: '上班族',
    family: '家庭',
    student: '学生',
    native: '本国人',
    foreigners: '外国人',
    includingParking: '含车位',
    starSchool: '明星学区',
    layout: '有格局图',
    above: '以上',
    caseType: '推案状态',
  },
  map: {
    RentalMapSearch: '租屋地图搜寻',
    RentalMapSearchEn: 'Rental Map Search',
    SaleMapSearch: '购屋地图搜寻',
    SaleMapSearchEn: 'Sale Map Search',
    preSaleMapSearch: '新建案地图搜寻',
    prsSaleMapSearchEn: 'Pre-Sale Map Search',
    factoryMapSearch: '厂房地图搜寻',
    factoryMapSearchEn: 'Factory Map Search',
    landMapSearch: '土地地图搜寻',
    landMapSearchEn: 'Land Map Search',
    officeMapSearch: '办公地图搜寻',
    officeMapSearchEn: 'Office Map Search',
    shopMapSearch: '店铺地图搜寻',
    shopMapSearchEn: 'Shop Map Search',
    listSearch: '列表查询',
    county: '县',
    district: '区域',
    type: '类型',
    minimumPrice: '请输入最低租金价格',
    maximumPrice: '请输入最高租金价格',
    minimumNewProjectPrice: '请输入最低单价价格',
    maximumNewProjectPrice: '请输入最高单价价格',
    minimumsSquare: '请输入最低平方公尺',
    maximumsSquare: '请输入最高平方公尺',
    selectTime: '请选择时间',
  },
  detail: {
    none: '无',
    can: '可以',
    not: '不可以',
    month: '月',
    months: '个月',
    tenThousand: '万',
    moveAnytime: '，可随时迁入',
    structure: '格局',
    room: '房',
    hall: '厅',
    bathroom: '卫',
    balcony: '阳台',
    proportion: '面积',
    squareMeter: 'm2',
    floor: '楼层',
    houseDirection: '房子方向',
    aroundTheLocation: '位置周边',
    location: '位置',
    surroundings: '周边',
    traffic: '交通',
    education: '教育',
    life: '生活',
    medical: '医疗',
    live: '土地/生活机能',
    equipmentAndRules: '设备与规则',
    equipment: '提供设备',
    homeEquipment: '住家设备',
    washingMachine: '洗衣机',
    refrigerator: '冰箱',
    network: '网络',
    television: '电视',
    payTelevision: '付费电视',
    waterHeater: '热水器',
    airConditioner: '冷氣',
    gas: '天然瓦斯',
    microWave: '微波炉',
    bed: '床',
    closet: '衣柜',
    sofa: '沙发',
    table: '桌椅',
    parking: '停车位',
    elevator: '电梯',
    fireSecurity: '消防保全',
    rentalRules: '租屋规则',
    pet: '养宠物',
    furnace: '可开伙',
    tax: '报税',
    householdRegistration: '入户籍',
    godTable: '神桌',
    identity: '身份要求',
    housingDetails: '房屋详情',
    factoryDetails: '厂房详情',
    landDetails: '土地详情',
    officeDetails: '办公详情',
    shopDetails: '店铺详情',
    rentalInstructions: '租屋说明',
    factoryRentalInstructions: '出租说明',
    rentIncludes: '租金含',
    deposit: '押金',
    managementFees: '管理费',
    minimumRentalPeriod: '最短租期',
    houseDescription: '房屋说明',
    factoryInfo: '厂房资讯',
    landInfo: '土地资讯',
    salesDescription: '销售说明',
    descriptionOfHousePropertyRegistration: '产权登记说明',
    houseQA: '房屋问答',
    factoryQA: '厂房问答',
    landQA: '土地问答',
    officeQA: '办公问答',
    shopQA: '店铺问答',
    houseQADescription: '输入提问内容，刊登者会第一时间给您回复',
    contentPLaceholder: '请输入内容',
    property: '产权',
    statutoryPurpose: '法定用途',
    buildingArea: '建物面积',
    similarHouses: '类似房屋',
    nearbyFactories: '附近厂房',
    nearbyLand: '附近土地',
    landlordPage: '查看店铺',
    sendQuestion: '发送提问',
    postedBy: '发表人',
    publishedIn: '发布于',
    viewAll: '查看全部照片',
    houseAge: '屋龄',
    parkingSpace: '车位',
    report: '检举此房屋',
    share: '分享',
    shareToFriends: '分享好房子给更多朋友！',
    copyLink: '复制连结',
    shareOnFb: '分享至Facebook',
    shareOnZalo: '分享至Zalo',
    shareother: '其他方式',
    notice: '88GO提醒您：刊登者以不方便看房、物件抢手为由「需先付订金」的是诈骗!',
    nearbyQuotations: '附近行情',
    investment: '投资建设',
    finished: '完工',
    handover: '交屋时间',
    houseCondition: '交屋屋况',
    openSale: '公开销售',
    loanRatio: '贷款成数',
    receptionCenter: '接待会馆',
    layout: '主推格局',
    salesInfo: '销售资讯',
    community: '社区规划',
    building: '栋',
    buildingPlanning: '栋户规划',
    aboveGround: '地上楼层',
    underGround: '地下楼层',
    buildingMaterials: '建材说明',
    builderInfo: '建商资讯',
    constructionCompany: '营造公司',
    architecturalDesign: '建筑设计',
    sellingByYourself: '自行销售',
    features: '特色说明',
    comparePrices: '周边比价',
    service: '服务时间',
    legalDocument: '法律文件',
    notUploadedYet: '暂未上传格局图',
    latestNews: '最新动态',
    viewsAll: '查看全部动态',
    newProjectStory: '建案动态',
    appQrcode: '使用应用程式扫描QR Code',
    clickLink: '点选连结',
    saleBySplits: '分割出售',
    widthOfTheFrontRoad: '临路路宽',
    buildingHeightLimit: '建物限高',
    specification: '规格',
    passengerElevator: '客梯',
    freightElevator: '货梯',
    includedInRent: '包含于租金内',
    nearbyOffice: '附近办公',
    nearbyShop: '附近店铺',
    averageRental: '附近出租成交均价',
    averageSale: '附近出售成交均价',
    averageAmount: '成交均价',
  },
  publisher: {
    rentList: '待租物件列表',
    saleList: '待售物件列表',
    more: '查看更多',
    noHouseForRent: '- 目前无出租中房屋-',
    noHouseForSale: '- 目前无出售中房屋-',
    openRating: '查看评价',
    currently: '目前共有',
    house: '间房屋',
    case: '建案',
    factory: '厂房',
    land: '土地',
    newHouse: '今日新增',
    items: '件',
    tabs: {
      home: '首页',
      rent: '待租物件列表',
      sale: '待售物件列表',
      detail: '详细介绍',
      message: '留言咨询',
    },
    working: '从业',
    views: '浏览人次',
    companyInfo: '公司资料',
    company: '就职公司',
    workingYears: '从业年限',
    workingExperience: '从业经历',
    qaDescription: '输入提问内容，业者会第一时间给您回复',
    onlyTenItems: '此处仅显示最新10件',
    evaluationDetails: '评价详情',
    summary: '评价摘要',
    evaluate: '评价',
    comment: '评论',
    rating: '评分',
    explanation: '说明',
    houses: '房屋',
  },
  info: {
    learnMore: '了解更多',
    outPlan: '我們的方案',
    outPlanEn: 'Our Membership Plan',
    agencyRegulations: '代理规章',
    agencyRegulationsEn: 'Agent',
    choose88go: '为何选择88go',
    choose88goEn: 'About 88go',
    disclaimer: '免责声明',
    disclaimerEn: 'Disclaimer',
    policy: '会员条款与隐私权声明',
    policyEn: 'Membership Rules & Privacy Policy',
  },
  about: {
    slogan1: '租购两相宜！',
    slogan2: '拥抱居住新未来，',
    welcome: '欢迎来到 88go房屋交易网！',
    description1: '我们是一个提供全方位居住解决方案的平台，无论你的居住需求如何，88go 都将成为你可靠的伙伴。我们期待在这个居住的旅程中，为你提供最优质的服务。与你携手实现居住的新未来。',
    slogan3: '房源多元，选择更多',
    description2: '88go 汇聚了众多优质的房源，涵盖各种需求和预算。从舒适的公寓到宽敞的别墅，从市区核心到宁静郊区，我们的房源种类繁多，一定能满足你的要求。我们也提供多装置的浏览方式，让您随时随地都能寻找理想的家园。',
    download: '下载APP',
    slogan4: '免仲介费，节省开支',
    description3: '我们坚信，租房买房应该轻松惬意，而不是负担沉重的费用。 88go 承诺，不再让繁琐的仲介费用成为你选屋的障碍。只要一点点会员费用，即可享有安心的房屋交易，并让房屋交易的利益最大化。',
    ourPlan: '了解我们的方案',
    slogan5: '租房无忧，伙伴照顾您！',
    description4: '在88go，我们致力于为您打造一个安心、顺畅的交易体验，让您能够放心选择理想的居所。我们的合作代书伙伴凭借他们丰富的经验和专业知识，协助您安心地面对房屋交易的每一步。无论是法律支援、交易保障还是细节处理，他们都将全力以赴，确保您的利益得到最大程度的保护。',
    slogan6: '让我们为您的房屋交易',
    slogan7: '增添信心与便利',
    getStared: '立即开始，寻找合适的人以及属于你的家！',
    description5: '在88go，我们为您提供了一个开始寻找您的理想居所以及提供良好房屋的平台。无论您是房客还是房东，我们都将帮助您找到最合适的家、最合适的交易方。让我们一起开始这段令人期待的旅程！',
    anyQuestion: '有任何疑问，欢迎与我们联系',
    contactUs: '联络我们',
  },
  plan: {
    slogan1: '连结梦想家，寻觅心选所',
    description1: '透过专业的刊登方案，你的房源将享有更大的曝光度和优越的展示效果。而加入我们的会员能获得更完整安全的房屋、房东资讯，并透过代理方案让你在交易中获得更多实惠。',
    slogan2: '让你的房子绽放独特光彩',
    description2: '最简易、快速的刊登方案！',
    rent: '出租',
    sale: '出售',
    advertise: '图片广告',
    viewImageAd: '图片广告刊登说明',
    whatIsAd: '什么是图片广告？',
    whatIsAdDes: '它是一种使用图像来吸引用户的广告形式，当用户点击时，它会指向您的网站或专门设计的登陆页面，又或是在网站 88gohouse.com 上发布的房屋刊登等页面。',
    benefitsOfBanner: '横幅广告的好处',
    benefitsOfBannerDes1: '1.品牌推广',
    benefitsOfBannerDes2: '2.增加您网站的流量',
    benefitsOfBannerDes3: '3.增加潜在客户，增加销售收入',
    imageLocation: '图片位置与尺寸说明',
    size: '尺寸(像素)',
    bannerAdWeb: '首页轮播图广告 网页版',
    bannerAdMobile: '首页轮播图广告 手机版',
    sidebarAd: '首页侧栏广告  网页版',
    notice: '注意事项',
    noticeDes1: '1.网页版首页轮播图因应画面配置，建议主要图文应放置于下方示意图绿色安全区块',
    noticeDes2: '2.根据屏幕类型，某些部分依旧可能会被遮挡',
    floatAd: '浮动广告 网页版',
    otherNotice: '其他注意事项',
    otherNoticeDes1: '1.单笔图片广告方案仅于指定位置上传一件图片广告',
    otherNoticeDes2: '2.一张图片广告的发布时间按30天计算',
    otherNoticeDes3: '3.图片广告申请成功后，审核时间约1-3个工作天',
    singleRentListing: '单笔出租刊登方案',
    singleSaleListing: '单笔出售刊登方案',
    rentListingDescription: '适合只有少量房屋刊登需求的屋主/代理人',
    basicPlan: '基本方案',
    basicPlanDescription: '适合想要进行简单刊登的房东或卖家',
    basicPlanDetail: {
      limit: '方案期限为',
      price: '元/两个月',
      publish: '可刊登',
      items: '笔',
      dollar: '元',
      point: '点',
      day: '天',
      month: '月',
      hours: '72小时内可更换物件地址次',
      times: '次',
      publishTime: '刊登期间为',
      noExposure: '无曝光方案',
      exposure: '曝光方式',
    },
    freePublishing: '赠送专人刊登',
    singleListingNotice: '※ 方案有效时间：购买之日起',
    singledaysofpurchase: '天內有效。',
    basicPlanNotice: '※ 方案有效时间：购买之日起',
    basicdaysofpurchase: '天内有效，方案中的物件需在有效期内使用完，逾期作废。',
    buyPlan: '购买此方案',
    bestDeal: '最划算',
    proPlan: '专业方案',
    proPlanDescription: '希望房源更突出地展示的房东或卖家',
    vipPlan: '贵宾方案',
    vipPlanDescription: '适合希望最大地突出房源的房东或卖家',
    slogan3: '快速、简单、有效的房地产经营',
    description3: '适合多间房屋有交易需求的刊登方案！',
    membershipPlan: '会员方案',
    membershipPlanDescription: '适合想要买房或是租房的会员',
    membershipPlanDetail: {
      landloardInfo: '可获得房东联络资讯',
      rateToLandloard: '可进行房东的评价',
      limit: '会员期限为',
    },
    slogan4: '脱颖而出，让你的房屋出类拔萃',
    description4: '加值服务，提供更多展示机会和可见度',
    service: {
      updateSort: '更新排序',
      manualUpdates: '手动更新',
      manualUpdatesDescription: '(手动点击更新排序)',
      automaticUpdates: '自动更新',
      automaticUpdatesDescription: '(系统自动更新排序)',
      regularUpdates: '定时更新',
      regularUpdatesDescription: '(自订更新次数与更新时间)',
      adService: '广告服务',
      prioritization: '优先排序',
      prioritizationDescription: '(始终排在普通刊登前)',
      preferred: '优选',
      preferredDescription: '(首页”优选” 区)',
      recommended: '推荐',
      recommendedDescription: '(列表”推荐” 区)',
      labelingService: '标示服务',
      pin: '置顶',
      pinDescription: '(拥有醒目置顶标识)',
      choice: '精选',
      choiceDescription: '(拥有醒目精选标识)',
      expedtedTag: '加急标签',
      expedtedTagDescription: '(拥有醒目加急标识)',
      publishByDedicated: '专人刊登',
      publishByDedicatedDescription: '(秘书协助刊登)',
    },
    slogan5: '让你的房屋交易旅程更加舒适',
    description5: '任何人都能使用的基本方案！',
    removeAdPlan: '去广告方案',
    removeAdPlanDescription: '适合不喜欢受广告打扰的会员',
    removeAllBanner: '可去除网站上所有广告',
    removeAgentPlan: '去仲介方案',
    removeAgentPlanDescription: '适合不希望仲介打扰的会员',
    removeAgentBanner: '可去除网站上所有仲介的刊登',
    qa: '常见问题',
    qaDescription: '对于我们的方案有任何疑问，欢迎与我们联系',
    qaContactUs: '联络我们',
    subErr: '子账号身份无法购买方案，如需购买请切换至仲介公司账号',
  },
  agent: {
    slogan1: '三代分润，共创居住收益！',
    subSlogan: '邀请他人加入，交易成功将为你带来利益',
    description1: '88go为了让每位会员都能享受更多回报，特别推出了我们独特的会员三代分润方案。当你成功邀请他人加入会员，对方的交易你也将获得利润分成。而更值得期待的是，你也能分享对方的代理分润，不仅限于你的交易，也包括你引荐的会员和他们所引荐的会员。',
    process: '代理的流程',
    join: '加入前',
    joinDescription2: '再加入88go前，您可以通过填写他人的代码，成为对方代理的一员。加入我们的会员后，您也获得能成为代理的机会。',
    becomeMember: '成为会员',
    becomeMemberDescription: '成为会员后，您将会获得一组专属的代理代码，您可以将这个代码分享给周遭的亲友，邀请他们一起加入88go。',
    becomeAgent: '成为代理',
    becomeAgentDescription: '成功邀請他人加入後，即擁有代理的身份，透過代理方案，您將擁有佣金收入，並且您所邀請的對象的交易利潤也能進行分成。',
    bonus: '如何计算佣金',
    benefits: '三代分润的好处',
    invite: '邀请多人',
    inviteDescription: '连锁效应让你的影响力和收益不断扩大，邀请越多人，获得的佣金也越多。',
    accounting: '帐务透明',
    accountingDescription: '每月1-30日的收入统计将在隔月汇款，，会员也能即时查看业绩回报，确保你的回报及时、透明。',
    profits: '隔代利润',
    profitsDescription: '每月1-即使是你引荐的会员引荐的会员，你都能分享他们的交易利益。',
    joinHands: '携手分享居住的荣耀！',
    joinHandsDescription: '这种连锁效应，让你的影响力和收益将持续扩大，成为一个居住共赢的社群中的重要一环。 88go相信，这种互惠互利的模式能够为每个会员带来更多价值，并让我们一同开创更美好的租购未来。',
    join88go: '加入88go的行列',
  },
  disclaimer: {
    slogan: '感谢您使用 88go房屋交易网 。',
    description1: '歡迎使用 88go房屋交易网（以下简称「我们」、「平台」）。',
    description2: '请在使用我们的服务之前详细阅读以下免责声明。使用我们的服务即表示您同意接受这些条款。',
    list: {
      title1: '1. 信息准确性',
      description1: '我们竭尽所能确保平台上的房源信息和内容准确完整。然而，我们无法对所有信息的准确性和完整性做出保证。您应自行判断并核实房源信息的正确性。',
      title2: '2. 租赁和购屋交易',
      description2: '2.1 我们仅提供租赁和购屋的信息平台，不参与实际交易。您在平台上的租赁或购屋交易为您自行决定，我们不对交易的履行或结果负责。',
      description3: '2.2 我们建议您在进行任何交易前进行充分的调查和审核，确保交易的合法性和可行性。',
      title3: '3. 第三方内容和服务',
      description4: '平台上可能包含来自第三方的内容和连结，这些内容和服务与我们无关，我们不对其准确性、合法性或安全性进行负责。若您使用这些内容和服务，将自行承担风险。',
      title4: '4. 使用风险',
      description5: '使用我们的服务将自行承担风险。我们不保证平台将始终无错误、安全、不受中断，也不对可能导致您的计算机系统受损的任何内容负责。',
      title5: '5. 法律责任限制',
      description6: '在适用法律允许的最大范围内，我们对因使用我们的服务而引起的任何损失或损害，不论是直接的、间接的、附带的、特殊的还是连带的，概不负责。',
      title6: '6. 免责声明的变更',
      description7: '我们保留随时更新免责声明的权利，请定期查看以确保您了解最新的内容。',
      description8: '如果您对免责声明有任何疑问，请联系我们。',
    },
    notice: '以上免责声明自 [生效日期] 起生效。<br>如果您对我们的条款有任何问题或疑虑，请联络我们。',
    contactUs: '联络我们',
  },
  policy: {
    slogan: '感谢您使用 88go房屋交易网 。',
    subTitle1: '88go房屋交易网 会员条款',
    subTitle2: '隐私权声明',
    description1: '歡迎您加入 欢迎您加入 88go房屋交易网（以下简称「我们」、「平台」）。请在使用我们的服务之前详细阅读以下会员条款。注册成为我们的会员即表示您同意遵守这些条款与声明。',
    description2: '我们非常重视您的隐私权，并致力于保护您的个人资讯。我们的隐私权声明详细说明了我们收集、使用和保护您的资讯的方式。',
    list: {
      title1: '1. 账号使用',
      description1: '1.1 您在注册时提供的个人资讯必须真实且准确。您的账号仅限您个人使用，不得转让、共享或出租给第三方。',
      description2: '1.2 您应保护您的账号和密码，并对所有在您的账号下发生的活动负责。如有任何怀疑账号遭到未经授权的使用，应立即通知我们。',
      title2: '2. 房东资格与仲介行为',
      description3: '2.1 我们的平台旨在提供租赁和购屋服务，并禁止在我们的平台上进行仲介行为。',
      description4: '2.2 若我们发现您身份为仲介人员，您的账号将被立即删除，并且您原本购买的方案将不予退费。',
      title3: '3. 会员费用与付款',
      description5: '3.1 您可以选择购买我们提供的不同方案，以获得更多特定的服务和功能。付款方式和费用将根据您选择的方案而定。',
      description6: '3.2 所有付款将以安全的方式进行，我们将保护您的付款信息。付款完成后，您将根据所选方案立即享有相应的服务。',
      title4: '4. 会员行为守则',
      description7: '4.1 您应遵守所有适用的法律法规，并在使用我们的平台时保持礼貌和尊重。',
      description8: '4.2 您不得在平台上发布虚假信息、侵犯他人权益的内容，或从事违法活动',
      title5: '5. 法律责任限制',
      title6: '6. 免责声明的变更',
    },
    privacy: {
      title1: '1. 收集的资讯',
      description1: '1.1 我们可能收集您在注册、使用服务、参加活动或与我们进行通讯时提供的个人资讯。',
      description2: '1.2 我们可能自动收集某些使用数据，如访问记录、IP 地址、设备信息，以改进我们的服务。',
      title2: '2. 资讯使用',
      description3: '2.1 我们使用您的资讯来提供、维护和改进我们的服务，并与您进行联系。',
      description4: '2.2 我们不会将您的个人资讯分享给未经授权的第三方。',
      title3: '3. 资讯保护',
      description5: '我们采取合理的安全措施保护您的资讯免受未经授权的访问、使用或披露。',
      title4: '4. 资讯删除',
      description6: "{'个人资讯的修改和删除，您有权查看、修改或删除我们持有的个人资讯。将您的请求透过电子邮件发送给我们的资料保护官 [您的手机号码]（地址为 88gohouse@gmail.com），我们将与您合作删除我们可能拥有的您的任何个人资料。'}",
    },
    notice: '以上会员条款及隐私权声明自 [生效日期] 起生效，我们保留随时更新的权利。请定期查看这些条款以确保您了解最新的信息。<br>如果您对我们的会员条款或隐私权声明有任何问题或疑虑，请联络我们。',
  },
  upgrade_account: {
    title1: '想要将您的业务提升至全新境界吗？',
    description1: '现在，透过我们的集团账号服务，您可以将您的业务推向全新高度！ 从现在开始，不仅仅是经营单一店面，您还可以扩展至多个分店，并且轻松管理每个分店的业绩，以及查看详尽的报表，一切尽在您的掌握之中！',
    learnMore: '了解方案详情',
    subTitle1: '升级账号后,您可以',
    title2: '设置多个分店账号，简易管理',
    description2: '您可以轻松地将分店帐号与集团账号连结，并查看每个分店的业务状态，且能有效地管理分店的客户名单与他们的需求。',
    subTitle2: '也可以',
    title3: '设立业绩目标，即时确认',
    description3: '分店可自行订定业务目标与发派任务，依据回报算出目标达成率。 集团通过帐户可以随时随地查看各分店动向，清楚掌握各店营运状态与方向。',
    subTitle3: '还能拥有',
    title4: '详尽报表，轻松分析业务状态',
    description4: '无论是各分店业绩报表、月目标总表还是客户需求表，一切尽在您的指尖，您可以即时掌握每个分店的业绩目标与实际成果表现，帮助您更好地制定整体销售策略和管理 方针。',
    title5: '立即升级，体验无限可能！',
    description5: '不再只是经营单一店面，现在就加入我们的集团账号服务，免费试用一个月，开启您事业的全新篇章！',
    monthlyFee: '月费',
    yearFee: '年费',
    title6: '探索集团帐户的优势 - 一个月免费试用！',
    description6: '从现在开始，我们很高兴能为您提供一个月的免费试用机会，让您发掘我们集团账户的所有功能和优势！ 没有风险，没有限制，只有您探索和发展企业的机会！',
    notice: '※ 试用期后若无升级动作，功能将会自动关闭',
    subscribe: '订阅',
    notice2: '※ 订阅日起30天内有效，到期时若无采取取消或更换方案之动作，将自动续订同一方案',
  },
  mortgageCalculator: {
    enTitle: 'Mortgage Calculator',
    loanAmount: '借款金额',
    loanTerm: '贷款期限',
    loanRate: '贷款利率',
    loanType: '贷款类型',
    average: '每月平均支付',
    paymentInEqual: '等额分期付款，以余额递减计算利息',
    chooseBankRate: '银行',
    rate: '利率',
    monthlyInterest: '每月利息',
    interestPayable: '应付利息',
    totalAmountPayable: '应付总额',
    expand: '展开具体资讯',
    collapse: '收起具体资讯',
    detail: '具体资讯',
    period: '周期数',
    balancePeriod: '每期余额',
    principalPayable: '应付本金',
    principalAndInterest: '本金+利息',
    total: '总计',
    description: '说明',
    para1: '贷款计算功能是越南商业银行提供的功能，可协助客户估算贷款所需支付的利息和本金金额。',
    para2: '要使用贷款计算功能，客户需要提供一些有关贷款的基本信息，包括：',
    loan: '贷款',
    para3: '根据此信息，该功能将计算并向客户显示以下信息：',
    list1: '每月支付的本金',
    list2: '每月需要支付的利息金额',
    list3: '支付全部贷款所需的总金额',
    para4: '越南银行贷款计算功能是一个有用的工具，可以帮助客户在决定借款之前估算贷款成本。此功能还可以帮助客户比较银行之间的利率和贷款条件，以选择最适合他们需求的贷款。',
    para5: '如何使用越南银行贷款计算功能',
    para6: '要使用越南银行贷款运算功能，客户可以按照以下步骤操作：',
    list4: '造访您想要借款的银行的网站或手机银行应用程式',
    list5: '寻找贷款计算器功能',
    list6: '输入有关您的贷款的必要资讯',
    list7: '按下“计算”按钮',
    para7: '要输入的讯息',
    list8: '贷款金额：您想向银行借钱的金额',
    list9: '贷款利率：您将为贷款支付的利率。贷款利率通常按年计算，但如果功能允许，您可以输入每月贷款利率',
    list10: '贷款期限：您需要偿还贷款的时间。贷款期限通常以年为单位计算，但如果功能允许，您可以以月为单位输入贷款期限',
    para8: '显示的讯息',
    list11: '每月本金支付：您每月需要支付的本金金额，以逐步减少贷款',
    list12: '每月利息支付：您每月需要为贷款支付的利息金额',
    list13: '整个贷款的支付总额：您需要支付的整个贷款的本金和利息总额',
    para9: '笔记',
    list14: '越南银行贷款计算功能显示的资讯仅供参考。实际利息和本金金额可能会因其他因素（例如通货膨胀率和市场利率）而有所不同',
    list15: '您应该咨询银行专家以获得有关您的贷款的详细建议。',
  },
  files: {
    download: '档案下载',
    downloadEn: 'Download',
    updateAt: '上次更新于:',
  },
  publish: {
    description: '请选择符合需求的刊登类型',
    checkType: '确认类型',
    information: '填写资料',
    confirmPublish: '确认刊登方案',
    complete: '完成刊登',
    thisAccount: '此账号',
    specifyAccount: '指定账号',
    rental: '出租刊登',
    sale: '出售刊登',
    plan: '刊登方案',
    factoryRental: '厂房出租刊登',
    factorySale: '厂房出售刊登',
    landRental: '土地出租刊登',
    landSale: '土地出售刊登',
    officeRental: '办公出租刊登',
    officeSale: '办公出售刊登',
    storeRental: '店铺出租刊登',
    storeSale: '店铺出售刊登',
    single: '单笔刊登',
    goToListing: '前往刊登',
    largeNumberOfItems: '有大量刊登物件的需求吗？',
    importObjectInBatches: '批量汇入物件',
    form: {
      housingCategory: '房屋类型',
      housingCypePlaceholder: '请选择类型',
      housingType: '房屋型态',
      factoryType: '厂房型态',
      landType: '土地型态',
      housingTypePlaceholder: '请选择型态',
      title: '刊登标题(字数最多99个字)',
      titlePlaceholder: '请输入标题',
      titleDesPlaceholder: '项目名称',
      rentalInformation: '出租资讯',
      saleInformation: '出售资讯',
      price: '单价',
      totalPrice: '总价',
      squareMeters: 'm2',
      household: '戶',
      priceIncludingParking: '含车位价格',
      priceNotIncludingParking: '不含车位价格',
      includingParking: '含车位',
      noIncludingParking: '不含车位',
      parkingSpacePrice: '车位价格',
      parkingArea: '车位面积(m2)',
      parkingAreaPlaceholder: '请选择车位类型',
      publicFacilities: '公设设施',
      toBeConfirmed: '待定',
      rent: '租金',
      dollarAndMonth: 'VND / 月',
      deposit: '押金',
      choosePlaceholder: '请选择',
      withLease: '在出租',
      minimumLease: '最短租期',
      rentalPeriod: '出租期限',
      canMoveIn: '可随时迁入',
      managementFees: '管理费',
      enterPlaceholder: '请输入',
      rentIncludes: '租金含',
      all: '全选',
      waterBill: '水费',
      electricityBill: '电费',
      payCable: '收费电视台',
      network: '网络',
      gasFee: '瓦斯费',
      manFee: '管理费',
      housingInformation: '房屋资讯',
      factoryInformation: '厂房资讯',
      landInformation: '土地资讯',
      officeInformation: '办公资讯',
      shopInformation: '店铺资讯',
      houseAddress: '房屋地址',
      factoryAddress: '厂房地址',
      landAddress: '土地地址',
      officeAddress: '办公地址',
      shopAddress: '店铺地址',
      hideDoorNumber: '隐藏门号',
      county: '省、市、街',
      district: '市、县、区',
      road: '市社、坊、村',
      addr: '地址',
      lane: '巷',
      alley: '弄',
      number: '号',
      of: '之',
      rentalFloor: '出租楼层',
      saleFloor: '出售楼层',
      totalFloor: '总楼层',
      communityName: '社区名称',
      communityNamePlaceholder: '请输入社区名称',
      legalPurpose: '法定用途',
      legalPurposePlaceholder: '请选择法定用途',
      orientation: '朝向',
      orientationPlaceholder: '请选择方向',
      decorationLevel: '装潢程度',
      decorationLevelPlaceholder: '请选择装潢程度',
      structure: '格局现况',
      structureDescription: '（请如实填写。若未如实填写，后续争议概不负责）',
      layout: '开放式格局',
      rooms: '房',
      hall: '厅',
      bathrooms: '卫',
      balcony: '阳台',
      area: '面积(m2)',
      houseAge: '屋龄(年)',
      houseAgeUnknown: '屋龄不详',
      areaDescription: '（请如实填写。若未如实填写，后续争议概不负责）',
      lifeFunction: '土地，生活机能',
      lifeFunctionDescription: '生活机能概述',
      nearSchool: '近学校',
      nearPark: '近公园',
      nearMarket: '近市场',
      nearStore: '近卖场',
      nearHospital: '近医院',
      nearNightMarket: '近夜市',
      nearConvenience: '近便利商店',
      nearMRT: '近捷运',
      starSchool: '明星学区',
      featureDescription: '请输入特色描述',
      lifePlaceholder: '例如 ：1. 土地资讯面积，朝向，红皮书等等。 地址，位置，转让总价。\n      2.周边便利设施如，近菜市场，学校，坊委办部门与商场等等.',
      provideEquipment: '提供设备',
      homeEquipment: '住家设备',
      basicEquipment: '基本设备',
      handlingEquipment: '装卸设备',
      washingMachine: '洗衣机',
      refrigerator: '冰箱',
      television: '电视',
      payTelevision: '付费电视',
      waterHeater: '热水器',
      airConditioner: '冷氣',
      gas: '天然瓦斯',
      microWave: '微波炉',
      bed: '床',
      closet: '衣柜',
      sofa: '沙发',
      table: '桌椅',
      parking: '停车位',
      elevator: '电梯',
      fireAndSecurity: '消防保全',
      fireExtinguisher: '灭火器',
      escalator: '缓降梯',
      lightingDevice: '照明设备',
      monitoringSystem: '监视系统',
      guardRoom: '警卫室',
      clothesDryingYard: '晒衣场',
      junkyard: '垃圾场',
      gymAndRhythmClassroom: '健身房/韵律教室',
      swimmingPool: '游泳池',
      ballSportsSpace: '球类运动空间',
      meetingSpace: '会议室/交谊厅/宴会厅',
      rentalRules: '租屋规则',
      petRestrictions: '宠物限制',
      noLimit: '不限',
      noPets: '禁宠物',
      catOnly: '限猫',
      dogOnly: '限狗',
      other: '限其他：',
      furnace: '开伙',
      furnacePlaceholder: '可/不可',
      tax: '报税',
      householdRegistration: '入户籍',
      godTable: '置神桌',
      identity: '身份要求',
      officeWorker: '上班族',
      family: '家庭',
      student: '学生',
      native: '本国人',
      foreigners: '外国人',
      environment: '房屋环境',
      factoryEnvironment: '厂房环境',
      imgNotice: '单张照片限制50MB内；默认第一张为封面照片。',
      videoNotice: '单部视频限制50MB内',
      layoutNotice: '单张格局图限制50MB内',
      housePicture: '房屋照片',
      factoryPicture: '厂房照片',
      selectFile: '选择档案',
      notUploadedYet: '尚未上传',
      vrPicture: '环景照片(VR)',
      video: '环境视频',
      layoutImg: '格局图',
      houseDescription: '房屋说明',
      factoryDescription: '厂房说明',
      contentPLaceholder: '请输入内容',
      houseDesPlaceholder: '如有土地,位置,面积,法律资讯等等\n比方：土地红皮书45米,河东郡,陈邓宁路',
      houseProperty: '房屋产权登记',
      factoryProperty: '厂房产权登记',
      landProperty: '土地产权登记',
      propertyRightsRegistration: '产权登记',
      completed: '已办理产权登记',
      notCompleted: '转让合约',
      pinkBook: '粉红皮书',
      buyAndSell: '购买和出售手写文件',
      legalPurposesOfaAd: '本广告法定用途：',
      buildingAreaOfAd: '本广告建物面积：',
      rentInAd: '本广告出租楼层：',
      notFilled: '未填写',
      housePropertyDescription: '若以上资料数据有误或未显示数据，您可以：',
      housePropertyCheck: '1.检查房屋地址填写是否正确',
      housePropertyUpload: '2.上传建物权状影本/线上建物誊本，本站将为您进行核实补充完整资料（上传资料仅作核实所用）',
      buildingTitlePhotocopy: '建物权状影本',
      houseManagement: '房屋代管',
      addObjectToEscrow: '是否将此物件加入代管？',
      escrow: '加入代管',
      yes: '是',
      no: '否',
      can: '可以',
      not: '不可以',
      next: '下一步',
      save: '保存资料',
      preview: '预览',
      returnToEdit: '返回编辑',
      bargainingPrice: '议价底价',
      toBeLeased: '在出租',
      layoutDiaram: '格局图',
      modifyAddrAlert: ' 小时内，剩余修改次数: ',
      usePartitions: '使用分区',
      factoryRegistration: '是否可工厂登记',
      companyRegistration: '是否可公司登记',
      dividedIntoCompartments: '是否可隔间',
      suitableForIndustry: '适合行业',
      disabledIndustry: '禁用行业',
      buildingStructure: '建筑结构',
      floorLoad: '楼板承载',
      asphaltRoad: '柏油路',
      concreteRoad: '水泥路',
      alleyStreet: '小路',
      widthOfTheFrontRoad: '临路路宽',
      buildingHeightLimit: '建物限高(米)',
      unloading: '卸货',
      passengerElevator: '客梯(数量)',
      freightElevator: '货梯(数量)',
      num: '数量',
      totalLandArea: '土地总面积',
      totalFactoryArea: '总面积',
      constructionArea: '建筑面积',
      specification: '规格(米)',
      meter: '米',
      mt: '吨',
      width: '面宽',
      verticalWidth: '纵宽',
      verticalDepth: '纵深',
      height: '层高',
      firstFloorHeight: '首层层高',
      deep: '深',
      baseArea: '基地面积',
      purpose: '适合用途',
      infrastructure: '基础设施',
      landStatus: '土地现况',
      landEnv: '土地环境',
      landPhotos: '土地照片',
      landDescription: '土地说明',
      landDescriptionPlaceholder: '如有：土地名称、私人地、规划案地、土地规格、使用期、法律相关资讯等\n比方：平阳省,顺安市,平准坊42号路,黎锋规划案。\n土地规格 4 x16.7=66.8 m2 , 100% 永久住宅地。\n使用期限：永久。',
      saleBySplits: '是否可分割出售？',
      mainBuilding: '主建物总面积',
      shareParts: '共用部分总面积',
      ancillaryBuildings: '附属建物总面积',
      formWarning: '以上资料请如实填写，若未如实填写，后续争议概不负责或需背负法律责任',
      officeCategory: '办公类型',
      officeType: '办公型态',
      useArea: '可使用面积',
      ownershipCertificate: '权证书面積',
      workstation: '可容纳工位数',
      unit: '个',
      carParking: '汽车位',
      motorParking: '机车位',
      industriesAndCommercialRegistration: '是否可工商登记',
      handoverOfHouse: '现况交屋',
      surroundingLiving: '周边生活机能',
      surroundingLivingPlaceholder: '请输入周边生活机能',
      officeEquipment: '办公设备',
      otherEquipment: '其他设备',
      officeEnv: '办公室环境',
      officePhotos: '办公室照片',
      shopCategory: '店铺类型',
      shopType: '店铺型态',
      shopInfo: '店铺资讯',
      operatingStatus: '经营状态',
      customerFlow: '客群人流',
      shopEquipment: '店铺设备',
      shopEnv: '店铺环境',
      shopPhotos: '店铺照片',
    },
    errMsg: {
      titleErr: '标题不能为空',
      cateErr: '请选择房屋类型',
      typeErr: '请选择房屋型态',
      priceErr: '租金不能为空',
      depositErr: '押金不能为空',
      minLeaseErr: '最短租期不能为空',
      rentalPeriodErr: '出租期限不能为空',
      mngFeeErr: '管理费不能为空',
      chooseErr: '请选择',
      saleFloorErr: '楼层不能为空',
      totalFloorErr: '总楼层不能为空',
      emptyErr: '不能为空',
      squareErr: '面积不能为空',
      rentalFloorInvalidErr: '总楼层不能比出租楼层低',
      saleFloorInvalidErr: '总楼层不能比售出楼层低',
      imageErr: '请上传至少 2 张，最多 25 张照片',
      videoErr: '上传最多 3 部視頻',
      layoutErr: '上传最多 3 张图片',
      singlePriceErr: '请填入金额',
      minPriceLargerErr: '最小金额不可比最大金额大',
      maxPriceSmallerErr: '最大金额不可比最大金额小',
      addressErr: '请输入地址',
      unitPriceErr: '请输入总价跟面积',
      less30CharactersErr: '请输入最少30字元',
    },
    selectExistingPlan: '选择现有刊登方案',
    buyPlan: '购买刊登方案',
    useExistingPlan: '使用现有方案',
    singlePosting: '单笔刊登',
    multiplePlans: '多笔方案',
    averagePrice: '平均单价',
    priceUnit: '元/笔',
    singlePostingRevise: '72小时内修改3次',
    singlePostingDay: '房屋刊登时间：30天',
    noExposure: '无加强曝光',
    selectPlan: '请选择方案',
    remainingOfItems: '剩余可刊登笔数：',
    items: '笔',
    remainingOfDays: '方案剩余天数：',
    days: '天',
    enhancedExposure: '加强曝光',
    valueAddedServices: '加值服务',
    updateSort: '更新排序',
    manualUpdate: '手动更新',
    manualUpdatesDescription: '(手动点击更新排序)',
    dollar: '元',
    tenThousand: '万',
    point: '点',
    bount: '次',
    automaticUpdates: '自动更新',
    automaticUpdatesDescription: '(系统自动更新排序)',
    regularUpdates: '定时更新',
    regularUpdatesDescription: '(自订更新次数与更新时间)',
    daily: '每日',
    timeToUpdate: '整点更新',
    hour: '小时',
    minute: '分钟，',
    update: '更新',
    adServices: '广告服务',
    preferred: '优选',
    preferredDescription: '(首页”优选” 区)',
    recommended: '推荐',
    recommendedDescription: '(列表”推荐” 区)',
    labelingService: '标示服务',
    pin: '置顶',
    pinDescription: '(拥有醒目置顶标识)',
    choice: '精选',
    choiceDescription: '(拥有醒目精选标识)',
    expedtedTag: '加急标签',
    expedtedTagDescription: '(拥有醒目加急标识)',
    webExposure: '网页版曝光',
    mobileExposure: '行动版曝光',
    exposureArea: '曝光区域',
    effectiveTime: '生效时间',
    validityPeriod: '有效期限',
    currentlyOwned: '目前拥有',
    exposureNotice: '注意：曝光效果可以重复购买，但是会复盖掉之前已购买过的曝光效果！建议评估过后再购买！',
    purchase: '购买',
    effectiveAfterPayment: '付款后即刻生效',
    exposurePlan: '曝光方案',
    customAmount: '自订金额',
    recommended88go: '88go推荐',
    exceptedRank: '预计今日排名',
    customAmountPlaceholder: '请输入自订金额',
    lessThan30: '（最小点数不小于23,000）',
    exposureDays: '曝光天数',
    customTime: '自订时间',
    customTimePlaceholder: '请输入自订时间',
    details: '明细',
    amountsPayable: '应付金额：',
    publishNow: '立即刊登',
    publishSuccess: '刊登成功',
    goToHousingInfo: '前往刊登资讯',
    publishManagement: '刊登管理',
    warningNotice: '*此页为预览刊登页面，并非正式刊登页面，请务必点击刊登按钮完成刊登流程',
    noPlan: '目前无购买的方案',
    estimated: '预计刊登时间',
    estimatedNotice: '如选择当日未选择时间将以现在小时数加一小时',
  },
  memberCenter: {
    home: '会员首页',
    account: '账号管理',
    message: '讯息管理',
    history: '历史纪录',
    promotions: '推广活动',
    transaction: '交易管理',
    advertisement: '广告管理',
    signOut: '登出',
    edit: '编辑个人资料',
    helloMember: '会员您好',
    switchIdentity: '切换身份',
    myAgent: '我的代理',
    promotionUrl: '推广网址',
    copy: '复制',
    vipTitle: '刊登好帮手，助您轻松成交！',
    vipDes: '享有多笔刊登，并赠送专人协助刊登物件',
    numberOfRegistered: '注册人数',
    numberOfPublishers: '刊登人数',
    numberOfPublications: '刊登数量',
    signUpBonus: '注册奖励',
    publicationBonus: '刊登奖励',
    promotionTotal: '推广总人数',
    promotionTotalBonus: '推广总奖金',
    startTime: '起始时间',
    endTime: '结束时间',
    downline: '推广人下线名称',
    publishStatus: '刊登状态',
    effectiveQuantity: '有效刊登数',
    invalidQuantity: '无效刊登数',
    registrationTime: '注册时间',
    objectName: '物件名称',
    invalidReason: '无效原因',
    peopleUnit: '人',
    dollar: '元',
    totalIncome: '本月总收入',
    totalCommission: '总佣金',
    myPoints: '我的点数',
    recharge: '我要储值',
    storedRecord: '储值纪录',
    basicPlan: '基本方案',
    items: '件',
    purchasePlan: '购买方案',
    projectManagement: '方案管理',
    recentFavorites: '最近喜爱的物件',
    more: '查看更多',
    noFavorite: '目前暂无喜爱的物件',
    see: '去逛逛',
    personalInfo: '个人资料',
    invoiceManagement: '发票管理',
    changePhoto: '变更大头贴',
    changeSubAccountPhoto: '变更子帐号照片',
    photoLimit: '*如需上传本人头像，请您上传五官清晰的免冠正面近距离照片<br>*图片限jpg、gif、png、格式，小于1M',
    workPhotoLimit: '*图片限jpg、gif、png、格式，小于1M',
    changeGroupPhoto: '变更集团照片',
    form: {
      name: '姓名',
      namePlaceholder: '请输入姓名',
      gender: '性别',
      genderPlaceholder: '请选择性别',
      phoneNumber: '手机号码',
      change: '变更',
      mailPlaceholder: '请输入email',
      pwd: '密码',
      set: '已设定',
      notSet: '未设定',
      changePwd: '修改密码',
      link: '连结',
      unlink: '取消连结',
      rating: '评分机制',
      qrcodeDownload: 'QR Code 下载',
      realName: '真实姓名',
      certificationStatus: '认证状态',
      notCertified: '未认证',
      certificationSuccess: '已认证',
      idCard: '身分证字号',
      idCardPlaceholder: '请输入身分证字号',
      approvalDate: '批证日期',
      approvalDatePlaceholder: '请输入批证日期',
      introducer: '介绍人',
      idCardPicture: '身分证图片',
      certificateType: '证书类型',
      certificatePicture: '证书图片',
      certificatePicturePlaceholder: '请上传图档',
      saleperson: '不动产营业员',
      agent: '不动产经纪人',
      certificatePictureNotice: '请确保姓名、证书编号、身分证字号等内容清晰可见，并遮住重要条码讯息。不可大于50M。',
      idNumberRemark: '(我们严格保护您的个资，该号码将不显示于前台)',
      idNumberContent: '详见身分证上传说明',
      accountOwnership: '账号归属',
      companyAccount: '集团账号',
      branchAccount: '分店账号',
      other: '其他',
      emergencyContact: '紧急联络人',
      contactIdentity: '联络人身份',
      contactIdentityPlaceholder: '请选择联络人身份',
      contactPhone: '联络人电话',
      brokerageName: '经纪业名称',
      brokerageNamePlaceholder: '请输入经纪业名称',
      workArea: '工作地区',
      company: '所属分店',
      belongToGroup: '您是集团旗下的分店成员吗？',
      joinToGroup: '申请加入集团分店',
      companyName: '公司名称',
      branchName: '分店名称',
      hideHouseDetail: '房屋详情页、店铺页隐藏公司讯息',
      position: '所属职位',
      positionPlaceholder: '请选择职位',
      branchType: '分店型态',
      directSale: '直営店',
      franchise: '加盟店',
      updateAccountPlan: '您拥有多家分店正在烦恼如何管理吗',
      updateAccountDes: '我们提供了集团适用的帐户升级方案！ 让您可以轻松管理旗下分店，掌握即时业务状态。',
      updateBtnText: '了解帐户升级方案',
      upgradeAccountTitle: '正在烦恼如何管理分店吗？',
      currentPlan: '目前升级方案',
      noPlan: '无方案',
      subscriptionTime: '订阅时间',
      settlementType: '结算类型',
      projectFee: '方案费用',
      subscriptionStatus: '订阅状态',
      storeName: '店铺名称',
      storeNamePlaceholder: '请输入店铺名称',
      serviceSlogan: '服务口号',
      serviceSloganPlaceholder: '请输入服务口号',
      serviceArea: '服务区域',
      addService: '新增服务区域(最多3项)',
      familiarCommuntity: '熟悉社区',
      experience: '从业经历',
      year: '年',
      month: '月',
      to: '至',
      in: '于',
      branch: '分店',
      takeOffice: '任',
      addExperience: '新增经历(最多3项)',
      certificateHonors: '证书荣誉',
      yearOfExperience: '执业年限',
      certName: '证书名称',
      get: '获得',
      addCert: '新增证书(最多3项)',
    },
    notice: '注意事项：',
    noticeParagraph1: '1.88go处理发票日期：会员储值后将在15个工作日内寄出。',
    noticeParagraph2: '2.若您仍有申请发票纸本的需求，请在储值过程中点选申请发票纸本寄送，填妥发票将在申请完成后7个工作日寄出。',
    noticeParagraph3: '3.若要索取三联式发票，烦请您联系',
    noticeParagraph4: '提供抬头、统编及发票寄送地址，我们将有专人为您处理。',
    save: '保存资料',
    invoiceList: '发票列表',
    changePhoneDescription: '请在下方输入您的新手机号码',
    phone: '手机号码',
    phonePlaceholder: '请输入手机号码',
    sendVerficationCode: '发送验证码',
    sendCode: '已发送4位验证码至',
    verify: '验证',
    verifySuccess: '手机变更成功，将跳转回账号管理页面',
    confirm: '确认',
    changePwd: '修改密码',
    changePwdDescription: '请在下方输入您的新密码',
    oldPwd: '旧密码',
    oldPwdPlaceholder: '请输入旧密码',
    newPwd: '新密码',
    newPwdPlaceholder: '请输入新密码8-12位包含英数字(不含大寫)',
    confirmNewPwd: '确认新密码',
    changeSuccessDescription: '密码变更成功，请重新登入',
    websiteNotification: '网站通知',
    houseQA: '房屋问答',
    editMessage: '编辑讯息',
    deleteMessage: '删除讯息',
    impeach: '检举',
    matchingObject: '媒合寻屋',
    previous: '上一则',
    next: '下一则',
    chooseAll: '全选',
    readSelected: '已读选取讯息',
    deleteSelected: '删除选取讯息',
    matchingText: '亲爱的88go会员 您好\n感谢您对我们租房网站的支持和关注。\n我们根据您的租房需求完成了以下媒合',
    recommendObject: '推荐物件',
    idealDes: '欢迎参考我们推荐的物件，找到您的理想物件！',
    historyType: '类型',
    caseNumber: '案件编号',
    caseNumberPlaceholder: '请输入编号',
    productName: '公司/产品名称',
    productNamePlaceholder: '请输入公司/产品名称',
    questionTime: '提问时间',
    questionTimePlaceholder: '请输入日期',
    search: '搜索',
    clear: '清除内容',
    title: '标题',
    publisher: '刊登者',
    publishType: '刊登类型',
    status: '状态',
    rent: '出租',
    sale: '出售',
    noReply: '未回复',
    replied: '已回',
    viewDetail: '查看详情',
    yourQuestion: '您的提问',
    userQuestion: '用户提问',
    yourImpeachContent: '您的检举内容',
    noProcessed: '未处理',
    processing: '处理中',
    closed: '已结案',
    publisherReply: '刊登者回复',
    customerReply: '客服回复',
    reply: '回复',
    replayLabel: '请输入您的回复内容',
    contentPLaceholder: '请输入内容',
    sendReply: '送出回复',
    previousPage: '回上一頁',
    watchHistory: '观看纪录',
    favoriteObjects: '喜爱的物件',
    transactionRecords: '房屋交易纪录',
    establishmentTime: '案件成立时间',
    sort: '排序',
    houseType: '房屋类型',
    area: '地区',
    noticeText: '*此交易纪录仅提供与88go的房屋交易纪录',
    caseType: '案件类型',
    propertyType: '房屋类型',
    price: '金额',
    personalManagement: '个人管理',
    performanceReport: '业绩报表',
    salesPersonReport: '业务报表',
    accountManagement: '帐户管理',
    overview: '代理总览',
    collectionAccount: '收款帐户',
    paymentRecords: '收款纪录',
    changeAccount: '变更帐户',
    totalPerformance: '总业绩',
    plantformTotalCommission: '平台总抽成',
    myCommission: '我的佣金',
    startMonth: '起始月份',
    startMonthPlaceholder: '请输入月份',
    level: '层级',
    people: '人数',
    accountWarningNotice: '*帐户第一位为预设收款帐户，若需修改帐户，请手动拖曳变更',
    addAccount: '新增帐户',
    deleteAccount: '删除帐户',
    agentRegulations: '代理规章',
    addAccountDescription: '请在下方新增您的银行帐户',
    bank: '银行',
    choosePlaceholder: '请选择',
    chooseExprYear: '到期年份',
    chooseExprMonth: '到期月份',
    breanchName: '分行名称',
    bankAccount: '银行账号',
    enterPlaceholder: '请输入',
    cashManagement: '金流管理',
    pointsManagement: '点数管理',
    plansManagement: '方案管理',
    upgradeManagement: '升级方案管理',
    paymentManagement: '付款管理',
    recharged: '充值',
    expenses: '支出',
    transactionTime: '交易时间',
    type: '类型',
    remark: '备注',
    paymentStatus: '付款状态',
    revenue: '收入',
    remitted: '汇款',
    point: '点',
    points: '点数',
    rechargedSuccess: '充值成功，剩余点数：',
    palnType: '方案类型',
    planName: '方案名称',
    purchaseTime: '购买时间',
    expireTime: '到期时间',
    numberOfPlans: '方案笔数',
    remainingTime: '剩余时间',
    continue: '我要续用',
    checkNotice: '请确认选择的方案是否正确',
    choosePlan: '选择方案',
    period: '会员期间',
    day: '天',
    planPoint: '方案点数',
    additional: '加购专人刊登',
    noAdditionalPurchase: '不加购',
    additionalPurchase: '加购，购买次数',
    purchaseCount: '购买次数',
    additionalPurchaseService: '加购服务',
    notEnoughPoints: '点数不足? 前往充值',
    confirmPayment: '确认付款',
    deleteCard: '删除卡片',
    creditCardsAcceptedOnly: '信用卡仅接受',
    creditCard: '信用卡',
    addCreditCard: '新增信用卡',
    addNewCard: '新增卡片',
    cardholderName: '持卡人姓名',
    creditCardNumber: '信用卡卡号',
    expiration: '信用卡有效日期',
    securityCode: '信用卡安全码',
    securityCodePlaceholder: '请输入安全码',
    nameplaceholder: '请输入姓名',
    rentalPlan: '出租方案',
    remainingTransactions: '剩余总件数',
    publishedBySecretary: '专人刊登数',
    notUse: '未使用赠送秘书刊登件数',
    salePlan: '出售方案',
    listing: '刊登中',
    listingDescription: '(当前页最多显示最新4笔)',
    publishManagement: '刊登管理',
    businessManagement: '业务经历',
    publish: '我要刊登',
    noPublish: '目前暂无刊登中物件',
    remaining: '剩余笔数',
    purchaseAgain: '再次购买',
    subscribeAgain: '再次订阅',
    housesClosed: '关闭中',
    removedHouses: '成交下架',
    editHouse: '未刊登',
    numberOfListing: '刊登中件数',
    numberOfSinglePublications: '单件刊登数',
    publishAll: '全部刊登',
    singlePosting: '单笔刊登',
    rentalPlanPublication: '出租方案刊登',
    salePlanPublication: '出售方案刊登',
    publicationNumber: '刊登编号',
    publicationTime: '刊登时间',
    housingInformation: '资讯',
    hoisingPrice: '金额',
    numberOfVisitors: '浏览人数',
    timeRemaining: '剩余刊登时间',
    operate: '操作',
    closeHousing: '关闭',
    dealHousing: '成交',
    improveEffect: '提升效果',
    improveEffectDes: '若选择提升效果，您的现有效果将失效<br>是否确认提升效果？',
    updateSort: '更新排序',
    currentNumberOfPublications: '目前刊登数',
    remainingListing: '剩余刊登数',
    proPlan: '专业方案',
    closureReason: '关闭原因',
    publishHouse: '刊登',
    removedTime: '成交下架时间',
    publishAgain: '再次刊登',
    numberOfTransactions: '成交件数',
    renew: '我要续刊',
    sortPlan: '排序方案',
    sortTime: '排序时间',
    adPlan: '广告方案',
    adTime: '广告时间',
    labelingPlan: '标示方案',
    labelingTime: '标示时间',
    enhanceExposure: '加强曝光',
    exposureArea: '曝光区域',
    exposureType: '曝光方式',
    exposureTime: '曝光时间',
    valueAddedServices: '加值服务',
    valueAddedCategory: '加值类别',
    valueAddedOption: '加值选项',
    valueAddedTime: '加值时间',
    publicationDate: '刊登日期',
    releaseDate: '发布日期',
    estimated: '预计刊登日期',
    listingStatus: '刊登状态',
    publicationTitle: '刊登标题',
    deleteListing: '删除刊登',
    editListing: '编辑刊登',
    editHouseDetail: '编辑',
    currentEffects: '现有效果',
    storeManagement: '店铺管理',
    certification: '资格认证',
    workData: '工作资料',
    uploadCertification: '上传职业资格证书',
    waitingForReview: '等待审核',
    reviewSuccessful: '审核成功',
    step1Des: '*您提交资料后，客服人员将在24小时内审核完毕，您可以在仲介职业认证内查看审核结果',
    submit: '确认提交',
    submissionSuccess: '资料提交成功，客服人员将于24小时内审核完毕，请耐心等待',
    retrunToMemberHome: '回到会员首页',
    emergencyNotice1: '1.紧急联络人仅为通知会员的一种渠道，无法代理会员行使账号权利。',
    emergencyNotice2: '2.通知紧急联络人视为已通知会员，故联络资讯若有变动，应即时更新。若紧急联络人有拒绝接听、未协助通知会员或联络不上等， 致会员未收到通知之情形，与88go无涉，会员须自行负责。',
    storeData: '店铺资料',
    storeQA: '店铺问答',
    flyers: '传单制作',
    storeInfo: '店铺资讯',
    businessExperience: '业务经历',
    introduce: '自我介绍',
    activate: '启用店铺',
    isActivateStore: '是否启用店铺?',
    qaNumber: '问答编号',
    questionAccount: '提问账号',
    choose: '选取',
    selectAll: '全选房屋',
    createFlyers: '制作传单',
    flyersProductionRecord: '传单制作纪录',
    checked: '已勾选',
    flyersListing: '件房屋刊登',
    productionDate: '制作日期',
    fileName: '档案名称',
    view: '检视',
    download: '下载',
    delete: '删除',
    subAccount: '子账号管理',
    total: '共有',
    subAccountGroup: '组子账号',
    addSubAccount: '新增子账号',
    subAccountInfo: '子账号资料',
    serialNumber: '编号',
    lastLoginTime: '上次登入时间',
    editAccount: '编辑',
    securityCodeOverErr: '安全码错误',
    securityCodeEmptyErr: '安全码不可为空',
    cardholderNameEmptyErr: '持卡人姓名不可为空',
    cardNumberEmptyErr: '信用卡卡号不可为空',
    cardTypeError: '信用卡卡号格式错误',
    advertisementRecord: '广告刊登记录',
    advertisementApplicationRecord: '广告申请记录',
    AdsRunning: '刊登中广告',
    closeAds: '关闭广告',
    adsArea: '刊登区域',
    adsInfo: '广告资讯',
    numberOfDaysRemaining: '剩余天数',
    adsPlan: '广告刊登方案',
    sidebarNotice: '首页侧栏广告请上传 199px * 600px',
    popupsNotice: '弹窗广告请上传 300px * 300px',
    floatNotice: '浮动广告请上传 970px * 90px',
    carouselNotice: '首页轮播图网页版请上传1920px * 533px',
    carouselNotice2: '首页轮播图手机版请上传428px * 200px',
    applyForAds: '申请',
    applyNotice: '请填写广告刊登资讯',
    publishTime: '刊登期间',
    adImage: '广告图片',
    urlPlaceholder: '请输入网址',
    qrcodePlaceholder: '请上传QR Code 图档',
    backToAdManagement: '回到广告管理',
    adNotice: '首页侧栏请上传 199 x 600px',
    promotionsRegulations: '推广活动规章',
    promotionsExpired: '已截止',
    myRequirements: '我的需求',
    birthday: '生日',
    birthdayPlaceholder: '请选择生日',
    socialMedia: '通讯软体',
    housingOptions: '住房选择',
    houseingType: '偏好型态',
    preferenceRegion: '偏好区域',
    preferenceArea: '偏好面积',
    amountRange: '金额范围',
    specialRequirements: '特殊需求',
    specialRequirementsPlaceholder: '请输入内容',
    requirementsDes: '填写您的房屋需求，让88go为您提供适合的房屋资讯！',
    clientManagement: '客户管理',
    newTask: '新增任务',
    editTask: '编辑任务',
    allDayTask: '全天任务',
    inComplete: '未完成任务',
    inProgress: '进行中任务',
    completed: '已完成任务',
    cancel: '取消任务',
    taskNumber: '任务编号',
    taskName: '任务名称',
    clientName: '客户姓名',
    taskContent: '任务内容',
    report: '回报',
    checkIn: '签到',
    taskList: '任务列表',
    schedule: '行程表',
    activity: '业务活动量',
    salePersonList: '业务列表',
    monthly: '月目标',
    monthlyActivity: '月活动量',
    missionHistory: '任务历程',
    targetTotal: '目标总业绩',
    totalTransaction: '成交总业绩',
    performance: '业绩',
    leaseEntrustment: '租赁委托(件)',
    buyingEntrustment: '买卖委托(件)',
    leaseVisit: '租赁带看(件)',
    transactionVisit: '买卖带看(件)',
    goalSetting: '月目标设定',
    performanceRegistration: '业绩登记',
    addInBatches: '新增目标',
    addNewGoal: '批量新增目标',
    performanceGoals: '业绩目标',
    addMonthlyGoal: '新增业务月目标',
    branchList: '分店列表',
    branchInfo: '分店资讯',
    branchNum: '分店数',
    branchApplication: '分店申请审核',
    editBranch: '编辑分店',
    addNewBranch: '新增分店',
    addGroupBranch: '新增集团分店',
    branchAccount: '分店帐号',
    ownerPosition: '帐号所有人职位',
    branchNotice: '分店申请若于7天内未审核将自动退回，请务必于时间内完成审核。',
    notReviewed: '未审核',
    applyForAccount: '申请帐号',
    applyForAccountPlaceholder: '请输入账号姓名或手机号码',
    applyStatus: '审核状态',
    applyStatusPlaceholder: '请选择审核状态',
    replyStatus: '回复状态',
    replyStatusPlaceholder: '请选择回复状态',
    applyAccountInfo: '申请帐号资讯',
    applyBranchInfo: '申请分店资讯',
    applyForReview: '申请审核',
    approved: '审核通过',
    notApproved: '审核未通过',
    trade: '买卖',
    lease: '租赁',
    houseOwner: '屋主',
    client: '客户',
    transactionItems: '交易项目',
    transactionType: '交易类型',
    homeownerType: '屋主类型',
    entrustment: '委任方式',
    homeownerName: '屋主姓名',
    homeownerAndContract: '屋主/契约',
    homeownerInfo: '屋主资讯',
    itemsSaleInfo: '标的物售资',
    salesInfo: '业务资讯',
    clientRequest: '客户需求',
    entrustedAudit: '委任审核',
    transactionNotReviewed: '买卖未审核',
    leaseNotReviewed: '租赁未审核',
    reviewedDes: '请确认委任方式是否正确，再点击审核通过。',
    contractDes: '请确认服务费金额是否正确，再点击审核通过。',
    chooseClientType: '请选择符合的客户类型与身份',
    clientIdentity: '客户身份',
    responsibleForSalesperson: '负责业务',
    demandArea: '需求区域',
    demandHouseCategory: '需求房屋类型',
    demandHouseType: '需求房屋型态',
    budget: '预算',
    taskDate: '任务日期',
    taskFormat: '任务形式',
    taskLocation: '任务地点',
    sameAsClient: '同客户联络地址',
    taskInfo: '任务资料',
    clientInfo: '客户资讯',
    contactName: '联络人姓名',
    contactAddress: '联络地址',
    reportInfo: '回报资料',
    contractAmount: '服务费金额',
    staringTime: '实际开始时间',
    endingTime: '实际结束时间',
    visitTime: '实际拜访时间',
    reportImgNotice: '单张照片限制50MB内，可以批量上传，请确认本人入镜。',
    reportPic: '照片',
    reportContent: '内容',
    addNewClient: '新增客户',
    clientNumber: '客户数',
    homeownerNumber: '屋主数',
    editClient: '编辑客户',
    visitRecord: '拜访纪录',
    visitDate: '拜访日期',
    taskStatus: '任务状态',
    cancelReason: '取消原因',
    taskDetail: '任务详情',
    deleteTask: '删除任务',
    willDeleteTask: '您将删除任务',
    checkToDelete: '是否确认删除?',
    salesPerson: '业务',
    vipsNumber: '负责贵宾数',
    totalNumberOfPublication: '本月委托总数',
    commissionHistory: '委托历程',
    vipsTotal: '贵宾总数',
    commissionAccount: '委托会员',
    commissionAccountPhone: '委托会员联络电话',
    numberOfEntrustPublication: '委托刊登数',
    numberOfComplimentaryPublications: '赠送委托刊登数',
    secretaryInfo: '秘书资讯',
    changeSecretary: '变更秘书',
    entrustmentRecord: '委托记录',
    numberOfPublication: '委托数',
    rank: '排名',
    totalTask: '任务总数',
    visitPercent: '拜访比例',
    branchAddrPlaceholder: '请输入分店地址',
    salesHistory: '业务任务历程',
    month: '月份',
    targetMonth: '目标月份',
    salesGoal: '业务目标',
    transactionPerformance: '成交业绩',
    autoSubscribe: '自动续订',
    upgradeAccount: '升级账号',
    morePlan: '更多升级方案',
    subscribing: '订阅中',
    expired: '已过期',
    reserve: '延期',
    noLicense: '您尚完成资格未认证，请务必完成认证。',
    goToCertification: '前往资格认证',
    goAddNewClient: '前往新增',
    groupName: '集团名称',
    waitingForReply: '待回复',
    applicationRecords: '分店申请记录',
    addTime: '新增时间',
    setBranch: '您的帐号被设为集团分店',
    setBranchDes: '将您的帐号设为旗下分店，请确认是否变更为此身份？',
    becomeBranchMember: '您已成为集团的分店成员！',
    addRequiredAreas: '新增需求区域',
    categoryOfHousingDemands: '需求房屋类型',
    typeOfHousingDemands: '需求房屋型态',
    typeOfFactoryDemands: '需求厂房型态',
    typeOfLandDemands: '需求土地型态',
    areaDemand: '需求面积',
    layoutDemand: '需求格局',
    performanceAudit: '审核',
    contractAudit: '签约审核',
    secretaryAssignment: '指派秘书',
    unassignedVIP: '未指派贵宾',
    vipsInfo: '贵宾资讯',
    planInfo: '方案资讯',
    assignmentStatus: '指派状态',
    responsibleSecretary: '负责秘书',
    generalClient: '一般客户',
    vip: '贵宾',
    branchDetail: '分店详情',
    checkInTime: '签到时间',
    checkInLocation: '签到地点',
    taskCheckIn: '任务签到',
    notCheckIn: '尚未签到',
    taskCheckInDes: '是否确认抵达拜访地点？',
    hasClientInfo: '取得客户资料',
    hasClientInfoDes: '是否于任务中取得客户资料？',
    realPrice: '实价登录',
    realPriceDes: '此资料仅用于网站统计，登录成交价格经审核3-7天将回馈会员点数10点',
    dealPrice: '成交金额',
    addNewSecretary: '新增秘书',
    secretaryManagement: '秘书管理',
    secretaryAccount: '秘书账号',
    freeDedicatedPublication: '次专人刊登',
    expiredOn: '到期于',
    clickFirst: '请先点此',
    downloadTemplate: '下载批量汇入物件范例',
    instructionsForUse: '使用说明',
    importDescription1: '请先下载汇入范例档案。',
    importDescription2: '依照表格内容填入相对应物件资讯。',
    importDescription3: '回到88go会员专区 > 刊登管理 > 未刊登房屋，点击上传物件档案，上传批量汇入物间表格。',
    importDescription4: '若上传档案无误，物件资料将自动汇入未刊登列表。',
    uploadFile: '上传档案',
    field: '栏位',
  },
  forumPage: {
    enTitle: 'Forum',
    forumHome: '讨论区首页',
    myArticles: '我的文章',
    articleType: '文章类型',
    searchArticlePlaceholder: '搜寻文章标题或作者',
    numberOfArticles: '文章数',
    numberOfReplies: '回复数',
    publishArticle: '发表文章',
    all: '所有文章',
    general: '一般文章',
    featured: '精选文章',
    popular: '热门文章',
    type: '类型',
    time: '发表时间',
    repliesViews: '回复/查看数',
    views: '查看数',
    login: '前往登入',
    canPublish: '即可发表文章与留言',
    registerAt: '注册于',
    edit: '编辑',
    delete: '删除',
    reply: '回复',
    replyTitle: '发表回复',
    replyDescription: '输入回复内容，并请务必遵守讨论区规范',
    send: '发送提问',
    rule: '讨论区规范',
    ruleDes: '送出文章前请务必详阅讨论区规范，所有会员皆须遵守以上原则，不同意者请勿在此发表言论。',
    ruleItem1: '88go交易网的会员可针对所有文章自由发表评论，本讨论区上的主题与内容皆不代表88go的立场。张贴言论请遵守法律规范，并符合本版的主旨，严禁灌水。',
    ruleItem2: '会员在发表文章之前，需确认所发之文章内容（图片）未侵害到他人的著作权、商标、专利等权利。若会员发表的文章产生法律相关责任，将由会员自行承担。',
    ruleItem3: '会员须同意不在讨论区发表任何具有伪造诈欺的、诽谤的、错误的、辱骂的、粗俗的、有害的、骚扰的，淫秽的，亵渎的、性别导向的、威胁侵犯他人隐私的或者违反任何法律规范的资讯。若遭检举发现，88go有权自行删除，情节重大将取消该会员之资格。',
    ruleItem4: '请勿留下身份证字号、住址等个人隐私资料，以免有心人士利用，此一情况88go将不负管理之责。',
    articleTitle: '文章标题',
    articleTitlePlaceholder: '请输入文章标题',
    articleContent: '文章内容',
    pin: '置顶',
    deletedMsg: '留言已删除',
    replyTo: '回复',
    editArticle: '编辑文章',
    joinComments: '登入/注册后可加入讨论',
  },
  dialog: {
    noLoginYet: '尚未登录',
    noLogin: '如需了解更多，请先登入或注册',
    goLogin: '前往登录',
    cancel: '取消',
    guestIdentity: '现在身份为买方/房客',
    guestDescription1: '您现在是买方/房客身份，无法刊登出租/出售广告',
    guestDescription2: '是否要切换身份？',
    switchIdentity: '切换身份',
    confirmUpload: '确认上传',
    noPoints: '点数不足',
    noPointsDescription: '您当前可使用点数不足，请先充值后再购买',
    remainPoints: '剩余点数:',
    pointsRequired: '需支付点',
    needToStore: '还需储值点数',
    store: '充值',
    storedPoints: '充值点数',
    paymentMethods: '付款方式',
    transfer: '转账付款',
    submit: '提交',
    payment: '前往付款',
    addSubAccount: '新增子账号',
    editSubAccount: '编辑子账号',
    memberStatus: '状态',
    deleteMsg: '删除讯息',
    deleteMsgDes: '是否确定删除讯息',
    confirmDeletion: '确认删除',
    reporting: '我要检举',
    reportContent: '检举内容',
    reportNow: '立即检举',
    adApplicationDetails: '广告申请详情',
    adInfo: '广告资料',
    applicationTime: '申请时间',
    applicantName: '申请人姓名',
    reviewResults: '审核结果',
    reason: '原因',
    expired: '已到期',
    comfirmClose: '确认关闭',
    closeDes: '是否确认关闭此物件？',
    closeNotice: '*刊登中物件关闭后其刊登时间照常计算，若有效期内关闭后再次开启，无需重新缴费刊登',
    dealHouse: '是否确认成交此物件',
    dealNotice: '*刊登中物件成交后，如要重新上架，加值费用需重新收费',
    deleteArticle: '删除文章',
    deleteArticleNotice: '是否确认删除文章？此动作将不可复原',
    deleteComment: '删除回复',
    deleteCommentNotice: '是否确认删除回复？此动作将不可复原',
    promotionDetail: '推广人下线详情',
    downlineName: '推广人下线名称',
    houseClosed: '物件已关闭，确认后将返回上一页',
    storeClosed: '店铺已关闭，确认后将返回上一页',
    orderTime: '订单时间',
    orderAmount: '订单金额',
    idContent: '身分证上传说明',
    idCardCover: '上传身分证时请遮住重要条码讯息 (QR Code)',
    idCardDescription: '并确保其他身份证资讯等内容清晰可见。 图片限jpg、gif、png格式，小于50M。',
    thirdPartyCondition: '第三方需要支付的金额: 最低限额: 10,000 VND 最高限额: 200,000,000 VND',
    applicantPosition: '申请人所属职位',
    belongToGroup: '所属集团',
    branchAddr: '分店地址',
    addBranch: '请确认分店申请内容是否正确，再点击审核通过。',
    accountName: '账号姓名',
    joinTheGroup: '加入集团',
    refuseToJoinTheGroup: '拒绝加入集团',
    checkDes: '是否确认此操作',
    deleteSecretary: '删除秘书',
    deleteSecretaryNotice: '是否确认删除秘书？此动作将不可复原',
    importDataError: '汇入资料错误',
    importDataErrorNotice: '汇入资料有误，请确认下方档案内容与错误代码，修正后再次上传。若有疑问，请联系客服。',
    sheetName: '工作表名称',
    systemError: '系统错误',
    systemErrorNotice: '系统错误，请稍后再试。若有疑问，请联系客服。',
  },
  clear: '清空',
  buyer: '买家/房客',
  owner: '屋主/代理人',
  estateAgent: '经理人/房屋仲介',
  estateAgency: '仲介公司',
  save: '储存',
  saveAndChange: '储存变更',
  enable: '启用',
  disable: '禁用',
  selectLanguage: '请选择语言',
  language: '语言',
  transfer: '转账付款',
  bankName: '行名',
  bankCode: '银行代码',
  bankAccount: '银行账号',
  storedImmediately: '立即充值',
  rechargedAmount: '充值金额',
  remittanceVerification: '汇款验证',
  submit: '提交',
  group: '集团',
  branch: '分店',
  code: {
    1: '成功',
    1001: 'Token过期',
    1002: '无效Token',
    1003: 'Token无权限',
    2001: '无效的请求',
    2002: '系统错误',
    2003: '资料库错误',
    2004: '没有权限',
    2005: '资料不存在',
    3001: '无效的账号',
    3002: '无效的密码',
    3003: '账号已存在',
    3004: '手机号码已存在',
    3005: '多次发送验证码',
    3006: '无效的验证码',
    3007: '验证码已过期',
    3008: '账号已验证',
    3009: '密码已存在',
    3010: '密码不存在',
    3011: '评价已存在',
    3012: '评价已关闭',
    3013: '账号已停用',
    3014: '无法切换当前角色',
    3015: '房屋不存在',
    3016: '店铺已关闭',
    3017: '店铺不存在',
    3018: '账号不存在',
    3019: '资格审核中',
    3020: '资格已认证',
    3021: '问答不存在',
    3022: '一小时内重复发文',
    3023: '一分钟内重复留言',
    3024: '无效的手机号码',
    3025: '无效的广告状态',
    3026: '支付方式不允许',
    3027: '订单状态错误',
    3028: 'Url已过期',
    3029: 'Url无效',
    3030: '未到活动时间',
    3031: '留言回覆发生错误',
    3034: '已达集团分店数量上限',
    3035: '分店已经在集团中或分店状态不正确',
    3036: '分店审核记录不存在或已审核',
    3037: '未完成签到',
    3038: '客户尚未委任审核',
    4001: '不是刊登房屋拥有者 或 房屋状态错误',
    4002: '房屋类型和方案类型不符',
    4003: '没有方案',
    4004: '没有剩余刊登数',
    4005: '没有剩余刊登期间',
    4006: '账号点数不足',
    4007: '房屋已经套用了同类型的加值方案',
    4008: '房屋已经套用了同类型的曝光方案',
    4009: '账号 id, role 或 account sub id 错误',
    4010: '续约方案类型不符',
    4011: '没有手动更新纪录',
    4012: '手动更新数量不足',
    4013: '手动更新时间小于 1 小时',
    4014: '账号角色为仲介或仲介公司，刊登前需要先填写资料，审核通过后才可以刊登',
    4015: '曝光率金额不能小于 19',
    4016: '已由后台关闭，无法重新刊登',
    4017: '最多只能预訂一个集团方案',
    4018: '集团方案只能升级',
    4020: '不是公司账号',
    4021: '不是该 VIP 的 秘书',
    4022: '尚未购买专人刊登',
    4023: '子账号不是秘书',
    4024: '尚有服务中的 VIP，无法删除',
    4025: '刊登中物件地址修改次数或修改时间已超过',
    5001: 'xlsx 错误',
    5002: '栏位为空',
    5003: '栏位长度错误',
    5004: '栏位数字错误',
    5005: '档案错误',
  },
  abuse_house: {
    status: {
      0: '未处理',
      1: '处理中',
      2: '已结案',
    },
  },
  account: {
    gender: {
      1: '男',
      2: '女',
      3: '未知',
    },
    status: {
      0: '停用',
      1: '启用',
    },
    verify_stat: {
      0: '未验证',
      1: '已验证',
    },
  },
  account_point_history: {
    type: {
      0: '全部',
      1: '购买点数',
      2: '刊登方案',
      3: '会员方案',
      4: '曝光率',
      5: '加值服务',
      6: '广吿方案',
      7: '代理方案',
      8: '人工入金',
      9: '推广活动',
      10: '集团方案',
      11: '升级点数补差额',
      12: '实价登录奖励',
      13: '专人刊登',
    },
    daily: '天 / 每天：',
  },
  plan_type: {
    type: {
      0: '全部',
      1: '基本',
      2: '出租',
      3: '出售',
      4: '广告',
    },
  },
  account_qi: {
    cert_type: {
      0: '',
      1: '營業員',
      2: '经理人',
    },
    ownership: {
      1: '集团',
      2: '分店',
      3: '其他',
    },
    company_position: {
      1: '董事长',
      2: '总经理/执行长',
      3: '经理',
      4: '店长',
      5: '经纪人/仲介人',
      6: '助理',
      7: '其他',
    },
    e_identity: {
      1: '秘书',
      2: '其他',
    },
    store_type: {
      1: '直营',
      2: '加盟',
    },
    status: {
      0: '未送审',
      1: '审查中',
      2: '审核通过',
      3: '审核失败',
    },
  },
  account_role: {
    type: {
      1: '买家/房客(会员)',
      2: '房东/代理人',
      3: '经理人/仲介',
      4: '仲介公司',
      5: '子账号',
    },
  },
  account_sub: {
    gender: {
      1: '男',
      2: '女',
      3: '未知',
    },
    status: {
      0: '停用',
      1: '启用',
    },
  },
  ad_apply: {
    block: {
      1: '首页侧栏',
    },
    type: {
      1: '前台申請',
      2: '后台申请',
    },
    status: {
      0: '未送审',
      1: '审查中',
      2: '核准（刊登时间到就=刊登）',
      3: '婉拒',
      4: '关闭刊登',
    },
  },
  ad_apply_bank: {
    status: {
      0: '未付款',
      1: '已付款',
    },
  },
  forum: {
    type: {
      1: '租屋',
      2: '购屋',
      3: '新建案',
    },
    tag: {
      0: '一般',
      1: '精选',
    },
    status: {
      0: '刊登中',
      1: '自行关闭',
      2: '后台关闭',
    },
    close_reason: {
      1: '与房屋无关',
      2: '与房屋重复',
      3: '其他',
    },
  },
  forum_comment: {
    status: {
      0: '正常',
      1: '自行删除',
      2: '后台删除',
    },
    del_reason: {
      1: '与房屋无关',
      2: '与房屋重复',
      3: '其他',
    },
  },
  house: {
    type: {
      1: '房屋出租',
      2: '房屋出售',
    },
    registration: {
      0: '转让合约',
      1: '已有红皮书',
    },
    saleRegistration: {
      0: '转让合约',
      1: '已有红皮书',
    },
    status: {
      0: '未刊登',
      1: '刊登过期',
      2: '自行關閉',
      3: '客服关闭',
      4: '刊登中',
      5: '成交下架',
    },
  },
  house_base_rule: {
    type: {
      1: '开火',
      2: '报税',
      3: '入户籍',
      4: '置神桌',
    },
  },
  house_equipment: {
    type: {
      1: '洗衣机',
      2: '冰箱',
      3: '网路',
      4: '電視',
      5: '付费电视',
      6: '热水器',
      7: '冷气',
      8: '天然瓦斯',
      9: '微波炉',
      10: '床',
      11: '衣柜',
      12: '沙发',
      13: '桌椅',
      14: '阳台',
      15: '停车位',
      16: '电梯',
    },
  },
  house_files: {
    type: {
      1: '照片',
      2: '格局图',
      3: '视频',
      4: 'VR',
    },
  },
  house_fire_security: {
    type: {
      1: '灭火器',
      2: '缓降梯',
      3: '照明设备',
      4: '监视系统',
    },
  },
  house_identity_rule: {
    type: {
      1: '上班族',
      2: '家庭',
      3: '学生',
      4: '本国人',
      5: '外国人',
    },
  },
  house_info: {
    house_category: {
      0: '不限',
      1: '套房',
      2: '雅房',
      3: '独立套房',
      4: '整层住家',
      5: '住宅',
      6: '法拍屋',
      7: '其他',
    },
    house_type: {
      0: '不限',
      1: '公寓',
      2: '电梯大楼',
      3: '透天',
      4: '别墅',
    },
    front: {
      0: '不限',
      1: '东',
      2: '南',
      3: '西',
      4: '北',
      5: '东南',
      6: '西南',
      7: '东北',
      8: '西北',
    },
    decoration: {
      0: '未知',
      1: '尚未装潢',
      2: '简易装潢',
      3: '中档装潢',
      4: '高档装潢',
    },
    parking_type: {
      0: '没有车位',
      1: '机械车位',
      2: '平面车位',
    },
    mng_fee_type: {
      0: '无',
      1: '月',
      2: '两个月',
      3: '季',
    },
    legally_usage: {
      0: '未知',
      1: '住家用',
      2: '商业用',
      3: '住商用',
      4: '工业用',
      5: '其他',
    },
  },
  house_pet_rule: {
    type: {
      1: '限猫',
      2: '限狗',
      3: '其他',
    },
  },
  house_public_facility: {
    type: {
      1: '接待大厅',
      2: '空中花园',
      3: '交谊厅',
      4: '阅览室',
      5: '健身房',
      6: 'KTV',
      7: '瑜伽教室',
      8: '信箱区',
      9: '晒被区',
      10: '烤肉区',
    },
  },
  house_rent_pay_includes: {
    type: {
      1: '水费',
      2: '电费',
      3: '付费电视',
      4: '网路',
      5: '瓦斯费',
      6: '管理费',
    },
  },
  message: {
    type: {
      1: '系统',
      2: '购屋通知',
    },
    status: {
      0: '未发布',
      1: '已发布',
    },
  },
  new_house: {
    case_type: {
      1: '预推屋',
      2: '预售屋',
      3: '新成屋',
    },
    house_type: {
      1: '公寓',
      2: '电梯大楼',
      3: '透天',
      4: '別墅',
    },
    sale_stat: {
      0: '未开始',
      1: '销售中',
      2: '售完',
    },
    status: {
      0: '未刊登',
      1: '刊登中',
      2: '客服关闭',
    },
    mng_fee_type: {
      1: '月',
      2: '两个月',
      3: '季',
    },
    front: {
      0: '不限',
      1: '东',
      2: '南',
      3: '西',
      4: '北',
      5: '东南',
      6: '西南',
      7: '东北',
      8: '西北',
    },
    deal_stat: {
      0: '标准配备',
      1: '简易装潢',
      2: '毛胚屋',
    },
  },
  new_house_files: {
    type: {
      1: '封面',
      2: '视频',
      3: 'VR',
      4: '平面',
      5: '交通',
      6: '示意',
      7: '实景',
      8: '环境',
      9: '实品',
    },
  },
  new_house_public_facility: {
    type: {
      1: '接待大厅',
      2: '空中花园',
      3: '交谊厅',
      4: '阅览室',
      5: '健身房',
      6: 'KTV',
      7: '瑜伽教室',
      8: '信箱区',
      9: '晒被区',
      10: '烤肉区',
    },
  },
  factory: {
    type: {
      1: '工厂',
      2: '厂办',
      3: '农舍',
      4: '仓库',
      5: '办公室',
    },
    deposit: {
      1: '一个月',
      2: '二个月',
      3: '三个月',
      4: '面议',
    },
    minLease: {
      1: '一年',
      2: '二年',
      3: '三年',
      4: '四年',
      5: '五年',
      6: '六年',
      7: '七年',
      8: '八年',
      9: '九年',
      10: '十年',
      11: '面议',
    },
    partitions: {
      1: '待确定',
      2: '工业区内',
      3: '工业区外',
    },
    structure: {
      1: '钢筋混凝土',
      2: '钢结构',
      3: '砖石结构',
      4: '混合结构',
      5: '砖木结构',
      6: '木质结构',
    },
    unloading: {
      1: '单面卸货',
      2: '双面卸货',
      3: '三面卸货',
      4: '四面卸货',
    },
    registration: {
      1: '是',
      2: '否',
      3: '待确定',
    },
    industry: {
      0: '不限',
      1: '金融保险',
      2: '咨询服务',
      3: 'IT 科技',
      4: '文化传媒',
      5: '教育培训',
      6: '汽车制造',
      7: '数码电子',
      8: '贸易零售',
      9: '服务',
      10: '医疗化工',
      11: '高精尖产业',
      12: '信息通讯',
      13: '快速消费品',
      14: '房地产',
      15: '游戏娱乐',
      16: '其他',
    },
    disabledIndustry: {
      0: '全选',
      1: '污染性行业',
      2: '化工行业',
      3: '噪音行业',
      4: '其他',
    },
    basic_equipment: {
      0: '全选',
      1: '380V三相电',
      2: '停车位',
      3: '天然瓦斯',
      4: '网路',
      5: '空调',
      6: '宿舍',
      7: '食堂',
    },
    handling_equipment: {
      0: '全选',
      1: '货梯',
      2: '备用发电机',
      3: '升降机',
      4: '起重机',
      5: '堆高机',
      6: '电动铲车',
      7: '托盘',
      8: '货架',
      9: '分拣线',
    },
  },
  land: {
    type: {
      1: '住宅地',
      2: '商業地',
      3: '工業地',
      4: '建地',
      5: '农地',
      6: '林地',
      7: '其他',
    },
    purpose: {
      0: '不限',
      1: '农作',
      2: '住宅',
      3: '加工厂',
      4: '中小企业',
      5: '高科技业',
      6: '厂房',
      7: '仓库',
      8: '广告',
      9: '店面',
    },
    infrastructure: {
      0: '全选',
      1: '电',
      2: '水',
      3: '通路',
      4: '地面平整',
      5: '网络线',
    },
  },
  office: {
    category: {
      1: '办公',
      2: '住宅式办公',
    },
    type: {
      1: '公寓',
      2: '别墅',
      3: '透天',
      4: '店铺',
      5: '电梯大楼',
    },
    tax: {
      0: '不含税',
      1: '含税',
    },
    electricity: {
      0: '不含电费',
      1: '含电费',
    },
    registration: {
      0: '待确定',
      1: '是',
      2: '否',
    },
    equipment: {
      1: '网路',
      2: '办公家具',
      3: '空调',
      4: '独立卫生间',
      5: '保全人员',
      6: '员工餐厅',
      7: '收发快递',
      8: '访客接待',
      9: '保洁服务',
      10: '洽谈室',
      11: '会议室',
      12: '书吧',
      13: '休闲区',
      14: '多功能厅',
      15: '茶水间',
      16: '自助售卖机',
      17: '电冰箱',
      18: '微波炉',
      19: '净水器',
      20: '空气净化器',
      21: 'WIFI',
      22: '健身设备',
      23: '复印/打印设备',
    },
  },
  shop: {
    category: {
      1: '商业街店面',
      2: '社区底商',
      3: '办公楼配套',
      4: '购物/百货中心店面',
      5: '路边/临街店面',
      6: '档口摊位',
      7: '交通设施商铺',
      8: '其他',
    },
    type: {
      1: '公寓',
      2: '别墅',
      3: '透天',
      4: '店铺',
      5: '电梯大楼',
    },
    business_status: {
      1: '空置中',
      2: '经营中',
      3: '出租中',
    },
    disabled: {
      1: '油烟餐饮业',
      2: '特种行业',
      3: '夹娃娃机',
      4: '宠物店',
      5: '洗车业',
      6: '游艺场',
      7: '宗教集团',
    },
    customer_flow: {
      1: '上班族',
      2: '附近居民',
      3: '游客',
    },
    equipment: {
      1: '自来水',
      2: '排污水',
      3: '380V三相电',
      4: '排烟管道',
      5: '排污管道',
      6: '天然瓦斯',
      7: '空调',
      8: '网路',
      9: '独立卫生间',
      10: '独立出入口',
      11: '热水器',
    },
  },
  ord: {
    status: {
      0: '未付款',
      1: '已汇款',
      2: '已付款',
      3: '未收到款项',
      4: '付款失败',
    },
    pay_type: {
      0: '人工入金',
      1: '汇款',
      2: '第三方',
      3: '活动结算',
      4: '实价登录奖励',
    },
  },
  ord_expose: {
    type: {
      1: '网页版',
      2: '行动版',
    },
  },
  plan_ad: {
    block: {
      1: '首页侧栏',
      2: '弹窗广告',
      3: '浮动广告',
      4: '首页轮播',
    },
    type: {
      7: '首页侧栏广告',
      8: '弹窗广告',
      9: '浮动广告',
      10: '首页轮播广告',
    },
    status: {
      0: '未送审',
      1: '审核中',
      2: '核准',
      3: '婉拒',
      4: '关闭刊登',
      5: '全部',
    },
  },
  plan_bonus: {
    category: {
      1: '更新排序',
      2: '广告服务',
      3: '标示服务',
    },
    type: {
      1: {
        1: '手动更新',
        2: '自动更新',
        3: '定时更新',
      },
      2: {
        1: '优选好屋',
        2: '推荐房屋',
      },
      3: {
        1: '置顶',
        2: '精选',
        3: '加急',
      },
    },
    status: {
      0: '停用',
      1: '启用',
    },
  },
  plan_lessor: {
    type: {
      1: '出租方案',
      2: '购屋方案',
      3: '单笔租方案',
      4: '单笔售方案',
    },
    status: {
      0: '停用',
      1: '启用',
    },
  },
  plan_premium: {
    type: {
      5: '去广告方案',
      6: '去仲介方案',
    },
    status: {
      0: '停用',
      1: '启用',
    },
  },
  rating: {
    type: {
      1: '房东/代理人',
      2: '店铺',
    },
  },
  store: {
    status: {
      0: '停用',
      1: '启用',
    },
  },
  publish_house_sort: {
    type: {
      1: '发布时间(最晚)',
      2: '发布时间(最早)',
      3: '创建时间(最晚)',
      4: '创建时间(最早)',
    },
  },
  publish_type: {
    type: {
      1: '出租',
      2: '出售',
      3: '出租',
      4: '出售',
      5: '出租',
      6: '出售',
      7: '出租',
      8: '出售',
      9: '出租',
      10: '出售',
    },
  },
  activity_type: {
    type: {
      1: '仲介经纪',
      2: 'Facebook 推广',
      3: 'Zola 推广',
      4: '部落客/网红',
    },
  },
  date_select: {
    1: '本日',
    2: '本周',
    3: '本月',
    4: '活动期间全部',
  },
  task_status: {
    1: '未完成',
    2: '进行中',
    3: '已完成',
    4: '取消',
  },
  review_status: {
    1: '未审核',
    2: '已通过',
    3: '驳回',
  },
  branch_reply_status: {
    3: '未回复',
    4: '同意',
    5: '婉拒',
  },
  owner_type: {
    1: '一般卖家',
    2: '建商',
    3: '仲介联卖',
  },
  appointment_type: {
    0: '待定',
    1: '口头约',
    2: '一般约',
    3: '专任约',
  },
  task_type: {
    1: '亲自拜访',
    2: '电话联络',
    3: '房屋带看',
    4: '签约',
  },
  contract: {
    0: '签约未审核',
    1: '签约已通过',
    2: '签约未通过',
  },
  position: {
    1: '店长',
    2: '经理',
    3: '副经理',
    4: '主管',
    5: '组长',
    6: '业务',
  },
  client_tabs: {
    0: '全部',
    1: '近期拜访',
    2: '两周未拜访',
  },
  assignment_status: {
    0: '未指派',
    1: '已指派',
  },
  placeholder: {
    minimumPrice: '请输入最低价格',
    maximumPrice: '请输入最高价格',
    minimumArae: '请输入最低面积',
    maximumArae: '请输入最高面积',
    minimumFloor: '请输入最低楼层',
    maximumFloor: '请输入最高楼层',
    searchId: '请输入编号',
    contractAmount: '请输入服务费金额',
    notApproved: '请输入原因',
    selectAnAccount: '请选择账号',
    selectIdentity: '请选择刊登身份',
  },
  alertText: {
    phone: '请输入手机号码',
    pwd: {
      error: '请输入密码8至12位包含英数字(不含大寫)',
      over: '密码超过12位',
      noMatch: '与密码不符',
    },
    point: '点数不足，将引导到点数管理',
    publishPriceErr: '最小金额不小于23000',
    publishDayErr: '天数不可小於0',
    areaErr: '请选择至少一項',
    logoutFail: '登出失败',
    planRoleNotPermit: '您目前的账号身份无法使用此方案，请切换到其他身份尝试',
    loginFirst: '请先登录',
    pleaseCheck: '请勾选',
    over50mb: '上传档案请勿超过50MB',
    over25Pics: '最多上传 25 张，请重新选择',
    maxPic: '上传已达最多数量',
    certType: '请选择证书类型',
    country: '请选择省、县市',
    area: '请选择区域',
    companyName: '请填写公司名称',
    branch: '请填写分店名称',
    flyersEmpty: '请勾选预制作传单的房屋',
    selectRating: '请选择评分',
    downloadFlyers: '下载传单请至制作纪录',
    rechargePoints: '请输入充值点数',
  },
  noticeText: {
    loginAgain: '请重新登入',
    addFavorite: '已加入收藏',
    removeFavorite: '已移除收藏',
    loginSuccess: '登录成功',
    logoutSuccess: '登出成功',
    modifiedSuccessfully: '修改成功',
    storeEnabled: '店铺已启用',
    storeClosed: '店铺已关闭',
    question: '提问已发送',
    closeHouseSuccess: '关闭成功',
    dealHouseSuccess: '成交成功',
    sendSuccess: '发送成功',
    updateSortSuccess: '更新排序成功',
    buySuccess: '购买成功',
    remittanceSuccess: '购买成功请于汇款后至交易管理填写汇款资料，以利确认点数是否已汇入。',
    addSubAccountSuccess: '新增子账号成功',
    accountIsEnabled: '账号已启用',
    accountIsDisabled: '账号已停用',
    deleteMsgSuceess: '讯息已删除',
    sendMsgSuccess: '已送出回复',
    reportSuccess: '检举已成功送出',
    openingSoon: '近期开放',
    certificationSuccess: '提交成功，请于24小时内静待审核',
    flyersSuccess: '制作传单成功',
    evaluationSuccess: '评价已送出',
    copiedSuccess: '复制成功',
    advertisementSuccess: '图片广告申请成功，审核时间约1-3个工作天若有任何疑问，欢迎洽询客服',
    publishArticleSuccess: '发表文章成功',
    deleteArticleSuceess: '文章已删除',
    modifyReplySuccess: '修改回复成功',
    deleteReplySuceess: '回复已删除',
    subSuccess: '订阅成功',
    verficationCodeSuccess: '已发送验证码',
    saveSuccess: '新增成功',
    subReportSuccess: '回报成功',
    deleteTaskSuccess: '删除成功',
    auditSuccess: '审核成功',
    openSubscriptionSuccess: '已启用自动续订',
    closeSubscriptionSuccess: '已关闭自动续订',
    checkInSuccess: '签到成功',
    uploadSuccess: '上传成功',
  },
  success: {
    save: '保存成功',
    purchasePlane: '购买成功，将引导到方案管理',
  },
  noDataText: {
    noData: '暂无资料',
    noMsg: '无讯息',
    noComments: '暂无评论',
    noLandMark: '附近没有相关地标',
    noPromotions: '目前暂无推广活动',
    noRecord: '目前暂无观看纪录',
    noClient: '未取得客户资料',
  },
  popularArea: [
    {
      name: '胡志明市',
      en: 'Ho Chi Minh City',
    },
    {
      name: '河內市',
      en: 'Ha Noi City',
    },
    {
      name: '海防市',
      en: 'Hai Phong City',
    },
    {
      name: '芹苴市',
      en: 'Can Tho City',
    },
    {
      name: '峴港市',
      en: 'Da Nang City',
    },
    {
      name: '同奈省',
      en: 'Dong Nai Province',
    },
    {
      name: '平陽省',
      en: 'Binh Duong Province',
    },
  ],
};

export default zhCn;
